import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, of, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { getDigitsAfterDot } from '@libs/common/helpers/get-digits-after-dot';
import { AqiType } from '@libs/common/enums/aqi.type';
import { ControlPointModel, InfoPins, IntervalEnum, MapPins, PinModel } from '@cityair/namespace';
import { MeasureScheme } from '@libs/common/enums/measure-scheme';
import { isRU, TEXTS } from '@libs/common/texts/texts';
import {
    selectGlobalMeasurement,
    selectIsCityMode,
    selectIsSidebarOpen,
    selectCurrentMapStyleType,
    selectMeasureScheme,
    selectUserTimezoneLabel,
} from '@cityair/modules/core/store/selectors';
import { MapAdapterService } from '@cityair/modules/map/services/map-adapter.service';
import { setMapStyleType } from '@cityair/modules/core/store/actions';

import { GroupFeaturesService } from '@cityair/modules/core/services/group-features/group-features.service';
import { selectMapStyleTypes } from '@cityair/modules/core/store/group/group.feature';
import MapboxActions from '@cityair/modules/map/components/mapbox/mapboxActions';
import { selectMapStyleLoading } from '@cityair/modules/core/store/map/map.feature';
import {
    NetworkActions,
    selectShowLayerOnMap,
    selectNetworkSchemaZones,
    selectDateRange,
    selectAllMmts,
    selectActiveMmt,
    selectActivePost,
    selectHoverPost,
    selectColorByPostId,
} from '@cityair/modules/network/store/network.feature';
import { CheckboxItem } from '@libs/common/types/checkbox-item';
import { CorrelationPeriod } from '@libs/common/models/basicModels';
import { GroupExtConfigName } from '@libs/common/enums/group-ext-config-name';
import { selectAllPosts } from '@cityair/modules/core/store/posts/posts.feature';

@Component({
    selector: 'network-map',
    templateUrl: './network-map.component.html',
    styleUrls: ['./network-map.component.less'],
})
export class NetworkMapComponent implements OnInit, OnDestroy {
    isRU = isRU;
    AqiType = AqiType;

    MeasureScheme = MeasureScheme;
    IntervalEnum = IntervalEnum;
    isLoading;
    selectGlobalMeasurement = selectGlobalMeasurement;
    selectIsSidebarOpen = selectIsSidebarOpen;
    selectDateRange = selectDateRange;
    selectAllMmts = selectAllMmts;
    selectActiveMmt = selectActiveMmt;
    selectNetworkSchemaZones = selectNetworkSchemaZones;
    selectShowLayerOnMap = selectShowLayerOnMap;

    selectMeasureScheme = selectMeasureScheme;
    selectIsCityMode = selectIsCityMode;
    setMapStyleType = setMapStyleType;
    selectCurrentMapStyleType = selectCurrentMapStyleType;
    selectMapStyleTypes = selectMapStyleTypes;
    selectMapStyleLoading = selectMapStyleLoading;
    selectUserTimezone = selectUserTimezoneLabel;

    isShowDropdownForPeriod = false;
    public periodList: CheckboxItem[] = [
        {
            label: TEXTS.ANALYSIS.correlation.dateNames.month,
            id: CorrelationPeriod.month,
            selected: true,
        },
    ];
    TEXTS = TEXTS;
    textsForMapControl = TEXTS.MAP_CONTROL;
    public ngDestroyed$ = new Subject<void>();
    constructor(
        readonly store: Store,
        private mapAdapterService: MapAdapterService,
        private groupFeaturesService: GroupFeaturesService
    ) {}

    ngOnInit() {
        this.store
            .select(selectAllPosts)
            .pipe(takeUntil(this.ngDestroyed$))
            .subscribe((data) => {
                const correlationPins: MapPins = {
                    getPins: this.store.select(selectAllPosts) as Observable<PinModel[]>,
                    getSelectedPinIds: this.store
                        .select(selectActivePost)
                        .pipe(map((data) => (data ? [data] : []))),
                    getValue: (pin) => of(null),
                    getHover: this.store
                        .select(selectHoverPost)
                        .pipe(map((data) => (data ? [data] : []))),
                    clickCb: (pin) => {},
                    getColor: (pin) =>
                        this.store.select(selectColorByPostId(pin?.id)) as Observable<string>,
                    isValueMode: of(false),
                };

                const groupFeaturesLayer = this.groupFeaturesService.getConfig(
                    GroupExtConfigName.featuresLayer
                );
                this.mapAdapterService.set({
                    cityPins: null,
                    postPins: null,
                    groupFeaturesLayer,
                    correlationPins,
                });
            });
    }

    ngOnDestroy() {
        this.mapAdapterService.set({ correlationPins: null });
        this.ngDestroyed$.next();
    }

    getDigits = (measureScheme: MeasureScheme) => (mmt) => getDigitsAfterDot(measureScheme, mmt);

    public updatePeriod($event) {
        const selected = $event.find((v) => v.selected);
    }

    public setActiveMmt($event) {
        this.store.dispatch(NetworkActions.setActiveMmt({ payload: $event }));
    }

    public toggleGridLayer() {
        this.store.dispatch(NetworkActions.toggleLayerMap({ payload: null }));
    }
}
