import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    OnDestroy,
    TemplateRef,
    ViewChild,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { TEXTS } from '@libs/common/texts/texts';
import { ANIMATION_OPACITY } from '@libs/common/consts/animation-opacity.const';
import {
    ImpactActions,
    selectIsLoading,
    selectConfigImpact,
    selectSourcesImpact,
    selectSuccessEditRun,
} from '@cityair/modules/impact/store/impact.feature';
import { IMPACT_PAGES } from '@cityair/modules/impact/models';
import { OffPanelPopupService } from '@cityair/modules/core/services/off-panel-popup.service';
import {
    RunConfigImpact,
    RunConfigImpactEdit,
} from '@cityair/modules/impact/service/api-model-impact';
import { selectUserRoleId } from '@cityair/modules/core/store/selectors';
import { ADMIN_ID } from '@cityair/config';
import { Router } from '@angular/router';
import { MAIN_PAGES } from '@libs/common/enums/main-pages';
import { takeUntil } from 'rxjs/operators';
import { addAlert } from '@cityair/modules/core/store/actions';
import { Subject } from 'rxjs';

@Component({
    selector: 'cs-impact-runs',
    templateUrl: './impact-runs.component.html',
    styleUrls: ['./impact-runs.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: ANIMATION_OPACITY,
})
export class ImpactRunsComponent implements OnDestroy {
    @ViewChild('actionsOutlet', { static: true }) actionsOutlet: TemplateRef<HTMLDivElement>;

    textsPlumes = TEXTS.PLUMES;
    textAction = TEXTS.COMMON.actions;
    errorListLoadText = TEXTS.FORECAST.errorListLoad;
    popupActive = false;
    popupPositionTop = 0;

    selectedCard: RunConfigImpact = null;
    popupOpenerElement: HTMLElement;
    ADMIN_ID = ADMIN_ID;

    selectUserRoleId = selectUserRoleId;
    selectIsLoading = selectIsLoading;
    selectConfigImpact = selectConfigImpact;
    selectSourcesImpact = selectSourcesImpact;
    currentEvent: Event;
    ngDestroyed$ = new Subject<void>();
    constructor(
        readonly store: Store,
        private _changeDetectorRef: ChangeDetectorRef,
        private router: Router,
        readonly popupProvider: OffPanelPopupService
    ) {
        this.store.dispatch(ImpactActions.setActiveTab({ payload: IMPACT_PAGES.configs }));
        this.store
            .select(selectSuccessEditRun)
            .pipe(takeUntil(this.ngDestroyed$))
            .subscribe((value) => {
                if (value) {
                    this.store.dispatch(
                        addAlert({
                            id: new Date().valueOf(),
                            messageKey: 'Update_Success',
                            positionX: 'left',
                            positionY: 'bottom',
                            iconClass: 'success',
                            duration: 2000,
                            showCloseIcon: true,
                            size: 'lg',
                        })
                    );
                    setTimeout(() => {
                        this.store.dispatch(ImpactActions.setSuccessEditRun({ payload: false }));
                        this.closeActionsPopup(this.currentEvent);
                    }, 2000);
                }
            });
    }

    ngOnDestroy() {
        this.popupProvider.setTemplate(null);
        this.ngDestroyed$.next();
    }

    openActionsPopup({ target, positionTop }, card: RunConfigImpact) {
        this.popupOpenerElement = target;
        this.popupProvider.confirm(() => {});
        this.popupProvider.setTemplate(this.actionsOutlet, () => (this.popupActive = false));
        this.popupActive = true;
        this.selectedCard = card;
        this.popupPositionTop = positionTop;
    }

    closeActionsPopup(e: Event) {
        if (this.popupActive && this.popupOpenerElement !== e.target) {
            this.popupProvider.setTemplate(null);
            this.popupActive = false;
            this.selectedCard = null;
        }
    }

    editRunConfiguration(e: Event, card: RunConfigImpact) {
        this.closeActionsPopup(e);
        this.router.navigate([
            `/${MAIN_PAGES.impact}/${IMPACT_PAGES.configs}/${IMPACT_PAGES.edit}/${card.id}`,
        ]);
    }

    stopRuns(e: Event, card: RunConfigImpact) {
        this.currentEvent = e;
        const config: RunConfigImpactEdit = {
            id: card.id,
            is_active: !card.is_active,
        };
        this.store.dispatch(ImpactActions.updateRunConfig({ payload: config }));
    }

    goToRunResult(card: RunConfigImpact) {
        this.router.navigate([`/${MAIN_PAGES.impact}/${IMPACT_PAGES.runResults}`]);
    }
}
