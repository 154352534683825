import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { TEXTS } from '@libs/common/texts/texts';
import { ANIMATION_OPACITY } from '@libs/common/consts/animation-opacity.const';

import {
    ImpactActions,
    selectActiveRun,
    selectIsLoading,
    selectIsLoadingRun,
    selectDateRangeImpactRuns,
    selectConfigById,
    selectRunsImpact,
} from '@cityair/modules/impact/store/impact.feature';
import { IMPACT_PAGES } from '@cityair/modules/impact/models';
import { DateRange } from '@cityair/modules/plumes/store/reducers';
import * as moment from 'moment-timezone';

import { filter, takeUntil } from 'rxjs/operators';
import { annotateWithDates } from '@libs/common/utils/date-formats';
import { RunImpact, RunImpactAnnotation } from '@cityair/modules/impact/service/api-model-impact';

@Component({
    selector: 'cs-impact-run-results',
    templateUrl: './impact-run-results.component.html',
    styleUrls: ['./impact-run-results.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: ANIMATION_OPACITY,
})
export class ImpactRunResultsComponent implements OnDestroy {
    labelPeriod = TEXTS.PLUMES.runResultLabelPeriod;
    textNoRunsAvailable = TEXTS.PLUMES.selectedPeriodNoRunsAvailable;
    nowOnMapText = TEXTS.PLUMES.nowOnMap;
    textPlumes = TEXTS.PLUMES;

    selectIsLoading = selectIsLoading;
    selectIsLoadingRun = selectIsLoadingRun;
    selectConfigById = selectConfigById;

    currentRunId: number;
    dateRangeFilterText: string;
    runs: RunImpactAnnotation[] = [];
    runsEmpty = false;
    dateRange: DateRange;
    calendarIsOpened = false;
    numberOfFutureDays = 1;

    handleUpdateDays: (numberOfFutureDays?: number, minDate?: string) => void;

    public dateTimeBegin: number;
    public dateTimeEnd: number;
    public ngDestroyed$ = new Subject<void>();

    constructor(readonly store: Store, private _cdRef: ChangeDetectorRef) {
        this.store.dispatch(ImpactActions.setActiveTab({ payload: IMPACT_PAGES.runResults }));
        this.store
            .select(selectDateRangeImpactRuns)
            .pipe(
                filter((v) => v !== null),
                takeUntil(this.ngDestroyed$)
            )
            .subscribe((dateRange) => {
                this.dateRange = dateRange;
                this.dateTimeBegin = moment(dateRange.start).valueOf();
                this.dateTimeEnd = moment(dateRange.end).valueOf();
            });
        this.store
            .select(selectRunsImpact)
            .pipe(takeUntil(this.ngDestroyed$))
            .subscribe((data) => {
                this.runsEmpty = data.length === 0;
                const sortRuns = data.sort((_a, _b) => {
                    const a = new Date(_a.evaluation_time || 0).getTime();
                    const b = new Date(_b.evaluation_time || 0).getTime();

                    return a < b ? 1 : a > b ? -1 : 0;
                });
                this.runs = annotateWithDates<RunImpact>((item) =>
                    moment(item.evaluation_time || 0).toDate()
                )(sortRuns);
            });
        this.store
            .select(selectActiveRun)
            .pipe(takeUntil(this.ngDestroyed$))
            .subscribe((activeRun) => {
                this.currentRunId = activeRun?.id ?? null;
            });
    }

    ngOnDestroy() {
        this.ngDestroyed$.next();
    }

    toggleCalendar() {
        if (this.calendarIsOpened) {
            this.handleUpdateDays(this.numberOfFutureDays);
        }
    }

    updateDays = (fn: (numberOfFutureDays?: number, minDate?: string) => void) =>
        (this.handleUpdateDays = fn);

    updateDateRange = (begin: number, finish: number) => {
        this.calendarIsOpened = false;
        const start = moment(begin).startOf('day').toISOString();
        const end = moment(finish).endOf('day').toISOString();
        this.store.dispatch(ImpactActions.clearRuns());
        this.store.dispatch(
            ImpactActions.updateDateRangeRun({
                payload: {
                    start,
                    end,
                },
            })
        );
    };

    selectRun(run: RunImpact) {
        if (this.currentRunId && this.currentRunId != run.id) {
            this.store.dispatch(ImpactActions.setActiveRun({ payload: run }));
        }
    }
}
