export const environment = {
    production: true,
    sentry: true,
    yandex_metrika: null,
    is_mobile_app: false,
    deploy_url: 'map-build/',
    api_url: 'https://mycityair.ru',
    harvester_v1_api_url: 'https://mycityair.ru/harvester/v1',
    vanga_api_url: 'https://vanga-api.cityair.ru/api',
    tile_server_url: 'https://tiles.cityair.ru',
    isAWS: false,
};
