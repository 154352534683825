import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    OnDestroy,
    OnInit,
    TemplateRef,
    ViewChild,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { TEXTS } from '@libs/common/texts/texts';
import { selectMeasureScheme } from '@cityair/modules/core/store/selectors';
import { getDigitsAfterDot } from '@libs/common/helpers/get-digits-after-dot';
import { GroupExtConfigName } from '@libs/common/enums/group-ext-config-name';
import { ANIMATION_OPACITY } from '@libs/common/consts/animation-opacity.const';
import MapboxActions from '@cityair/modules/map/components/mapbox/mapboxActions';
import {
    ImpactActions,
    selectActiveRun,
    selectActiveMmt,
    selectConfigImpactDic,
    selectRunsImpact,
    selectIsLoadingRun,
    selectControlPoints,
    selectIsLoadingControlPoints,
    getControlPointImpactValue,
    selectActivePin,
} from '@cityair/modules/impact/store/impact.feature';
import { IMPACT_PAGES } from '@cityair/modules/impact/models';
import { MeasureScheme } from '@libs/common/enums/measure-scheme';
import { OffPanelPopupService } from '@cityair/modules/core/services/off-panel-popup.service';
import { GroupFeaturesService } from '@cityair/modules/core/services/group-features/group-features.service';
import {
    ControlPointImpact,
    RunImpact,
    RunImpactAnnotation,
} from '@cityair/modules/impact/service/api-model-impact';
import { takeUntil } from 'rxjs/operators';
import { annotateWithDates } from '@libs/common/utils/date-formats';
import * as moment from 'moment-timezone';

@Component({
    selector: 'cs-impact-control-points',
    templateUrl: './control-points.component.html',
    styleUrls: ['./control-points.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: ANIMATION_OPACITY,
})
export class ControlPointsComponent implements OnInit, OnDestroy {
    @ViewChild('actionsOutlet', { static: true }) actionsOutlet: TemplateRef<HTMLDivElement>;
    @ViewChild('popupDeleteOutlet', { static: true })
    popupDeleteOutlet: TemplateRef<HTMLDivElement>;

    selectMeasureScheme = selectMeasureScheme;

    selectActiveRun = selectActiveRun;
    selectActiveMmt = selectActiveMmt;
    selectConfigImpactDic = selectConfigImpactDic;
    selectIsLoadingRun = selectIsLoadingRun;
    selectIsLoadingControlPoints = selectIsLoadingControlPoints;
    selectControlPoints = selectControlPoints;
    getControlPointImpactValue = getControlPointImpactValue;

    textsPlumes = TEXTS.PLUMES;
    editStation = TEXTS.EDIT_STATION;
    textsScheme = TEXTS.MEASURES_SCHEME;

    public mmtNames = TEXTS.NAMES;

    errorListLoadText = TEXTS.FORECAST.errorListLoad;
    GroupExtConfigName = GroupExtConfigName;
    measureScheme = MeasureScheme;
    getDigitsAfterDot = getDigitsAfterDot;

    runs: RunImpactAnnotation[] = [];
    sortingControlPoint;
    sortingDirection = 1;

    public activeControlPoint: any;
    public loading;
    popupActive = false;
    popupPositionTop = 0;
    timeIndex: number;
    selectedPoint: ControlPointImpact = null;

    currentMeasure: string;
    popupOpenerElement: HTMLElement;
    isDeletePopupOpen: boolean;
    popupDeleteTexts = Object.assign({}, TEXTS.PLUMES.popupDelete);
    runsEmpty = false;
    public ngDestroyed$ = new Subject<void>();
    constructor(
        readonly store: Store,
        private _changeDetectorRef: ChangeDetectorRef,
        readonly popupProvider: OffPanelPopupService,
        public groupFeaturesService: GroupFeaturesService,
        private mapActions: MapboxActions,
        private router: Router,
        private route: ActivatedRoute
    ) {
        this.store.dispatch(ImpactActions.setActiveTab({ payload: IMPACT_PAGES.controlPoints }));
        store
            .select(selectRunsImpact)
            .pipe(takeUntil(this.ngDestroyed$))
            .subscribe((data) => {
                this.runsEmpty = data.length === 0;
                const sortRuns = data.sort((_a, _b) => {
                    const a = new Date(_a.evaluation_time || 0).getTime();
                    const b = new Date(_b.evaluation_time || 0).getTime();

                    return a < b ? 1 : a > b ? -1 : 0;
                });
                this.runs = annotateWithDates<RunImpact>((item) =>
                    moment(item.evaluation_time || 0).toDate()
                )(sortRuns);
            });
        store
            .select(selectActiveMmt)
            .pipe(takeUntil(this.ngDestroyed$))
            .subscribe((data) => (this.currentMeasure = data));
        store
            .select(selectActivePin)
            .pipe(takeUntil(this.ngDestroyed$))
            .subscribe((data) => (this.activeControlPoint = data));
    }

    ngOnInit() {
        this.sortingDirection = 1;
        this.sortingOfControlPoint(this.sortControlPointName);
    }

    ngOnDestroy() {
        this.ngDestroyed$.next();
    }

    sortControlPointName = (cp: ControlPointImpact) => cp.name;
    sortControlPointValue = (cp: ControlPointImpact) => {
        /* if (cp.timeline.hasOwnProperty(this.currentMeasure) && cp.timeline[this.currentMeasure]) {
            return cp.timeline[this.currentMeasure][this.timeIndex];
        } */

        return null;
    };

    sortingOfControlPoint = (sortItem) => {
        if (this.sortingControlPoint === sortItem) {
            this.sortingDirection *= -1;
        } else {
            this.sortingControlPoint = sortItem;
        }
    };

    openAddCheckpoint = () => {};

    deleteControlPoint = (id: number) => {};

    editControlPoint = () => {};

    openControlPointChart(point: ControlPointImpact) {
        this.store.dispatch(ImpactActions.setActivePin({ payload: point.id.toString() }));
    }

    openActionsPopup($event, point: ControlPointImpact) {
        this.popupOpenerElement = $event.target;
        this.popupProvider.setTemplate(this.actionsOutlet);
        this.popupActive = true;
        this.selectedPoint = point;
        this.popupPositionTop = $event.positionTop;
    }

    closeActionsPopup(e: Event) {
        if (this.popupActive && this.popupOpenerElement !== e.target) {
            this.popupProvider.setTemplate(null);
            this.popupActive = false;
            this.selectedPoint = null;
        }
    }

    deleteControlPointAccept = () => {
        this.closeDeleteControlPointDialog();
        this.deleteControlPoint(this.selectedPoint.id);
    };

    closeDeleteControlPointDialog = () => {
        this.isDeletePopupOpen = false;
        this.popupProvider.setTemplate(null);
    };

    deleteControlPointDialog(e: Event, point: ControlPointImpact) {
        this.closeActionsPopup(e);
        const { body } = this.textsPlumes.popupDelete;
        this.popupDeleteTexts.body = body.replace('%s', point.name);
        this.isDeletePopupOpen = true;
        this.popupProvider.setTemplate(this.popupDeleteOutlet);
        this.selectedPoint = point;
    }

    onScroll(e: Event) {
        this.closeActionsPopup(e);
    }
}
