import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { TEXTS } from '@libs/common/texts/texts';

import { Store } from '@ngrx/store';
import { Dictionary } from '@ngrx/entity';
import {
    RunConfigImpact,
    RunImpact,
    RunImpactAnnotation,
} from '@cityair/modules/impact/service/api-model-impact';
import { ImpactActions } from '@cityair/modules/impact/store/impact.feature';

@Component({
    selector: 'ca-impact-run-dropdown-list',
    templateUrl: './impact-run-dropdown-list.component.html',
    styleUrls: ['./impact-run-dropdown-list.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ImpactRunDropdownListComponent {
    @Input() currentRun: RunImpact;
    @Input() runs: RunImpactAnnotation[];
    @Input() configs: Dictionary<RunConfigImpact>;
    @Input() isLoadingRun: boolean;
    textPlumes = TEXTS.PLUMES;

    isShowList = false;
    constructor(private store: Store) {}

    toggleList() {
        this.isShowList = !this.isShowList;
    }

    selectRun(item: RunImpact) {
        if (this.currentRun && this.currentRun.id != item.id) {
            this.store.dispatch(ImpactActions.setActiveRun({ payload: item }));
            this.toggleList();
        }
    }

    clickOutside() {
        this.isShowList = false;
    }
}
