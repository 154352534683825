import { Pipe, PipeTransform } from '@angular/core';
import { declOfNum } from '@libs/common/utils/utils';
import { LANGUAGE, TEXTS } from '@libs/common/texts/texts';
import { NgLocalization } from '@angular/common';

@Pipe({ name: 'configPeriodTime' })
export class ConfigPeriodTimePipe implements PipeTransform {
    constructor(private ngLocalization: NgLocalization) {}
    transform(period: number): string {
        const hours = period / 60;
        const everyCategory = this.ngLocalization.getPluralCategory(hours, LANGUAGE);

        return `${TEXTS.PLUMES.every[everyCategory]} ${hours} ${declOfNum(
            hours,
            TEXTS.PLUMES.timeIntervalHours
        )}`;
    }
}
