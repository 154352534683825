import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { LANGUAGE, TEXTS } from '@libs/common/texts/texts';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import {
    NetworkActions,
    selectDateRange,
    selectPriorityDataByMmt,
    selectActiveMmt,
    selectPostDataByMmt,
} from '@cityair/modules/network/store/network.feature';
import { DateRangeNetwork } from '@cityair/modules/network/service/api-models';
import { ugM } from '@libs/common/texts/texts_ru';
import { LIST_PRIORITY_LIMIT } from '@cityair/modules/network/constants';
import { NgLocalization } from '@angular/common';
import {
    selectAllPosts,
    selectPostNameById,
} from '@cityair/modules/core/store/posts/posts.feature';
import { NOTIFICATIONS } from '@libs/common/texts/texts_en';

@Component({
    selector: 'cityair-network-main',
    templateUrl: './network-main.component.html',
    styleUrls: ['./network-main.component.less'],
})
export class NetworkMainComponent implements OnInit, OnDestroy {
    public translateText = TEXTS.NETWORK;
    public title = TEXTS.ADMIN_PANEL.network;
    public ngDestroyed$ = new Subject<void>();
    public isOperator = false;
    text = TEXTS.ANALYSIS;
    textMonitoring = TEXTS.ANALYSIS_EVENTS.monitoring;
    public textNames = TEXTS.NAMES;
    public currentLang = LANGUAGE;
    showInfoPopup = false;
    public dateRange: DateRangeNetwork = null;

    selectPriorityDataByMmt = selectPriorityDataByMmt;
    selectPostDataByMmt = selectPostDataByMmt;
    selectPostNameById = selectPostNameById;

    public sortDirectionPriority = 1;
    public sortFieldPriority: string;
    public sortDirection = 1;
    public sortField: string;
    public activeMmt: string;
    public ugM = ugM;
    LIST_PRIORITY_LIMIT = LIST_PRIORITY_LIMIT;
    currentLimit = LIST_PRIORITY_LIMIT;
    currentLimitPost = LIST_PRIORITY_LIMIT;

    constructor(
        public store: Store,
        private _cdRef: ChangeDetectorRef,
        private ngLocalization: NgLocalization
    ) {
        this.store
            .select(selectAllPosts)
            .pipe(
                takeUntil(this.ngDestroyed$),
                filter((v) => v !== null && v.length > 0)
            )
            .subscribe(async (group) => {
                if (group) {
                    this.store.dispatch(NetworkActions.initModule());
                }
            });
        this.store
            .select(selectDateRange)
            .pipe(takeUntil(this.ngDestroyed$))
            .subscribe(async (dates) => {
                this.dateRange = dates;
            });
        this.store
            .select(selectActiveMmt)
            .pipe(takeUntil(this.ngDestroyed$))
            .subscribe(async (mmt) => {
                this.activeMmt = mmt;
            });
    }

    ngOnInit(): void {
        this.setSortingCbPriority('value');
        this.setSortingCb('value');
    }

    ngOnDestroy(): void {
        this.store.dispatch(NetworkActions.setIsAvailable({ payload: false }));
        this.ngDestroyed$.next();
    }

    toggleLimit(count) {
        this.currentLimit = count;
    }

    public setSortingCbPriority(sortCb: string): void {
        if (this.sortFieldPriority === sortCb) {
            this.sortDirectionPriority *= -1;
        } else {
            this.sortFieldPriority = sortCb;
        }
    }

    public setSortingCb(sortCb: string): void {
        if (this.sortField === sortCb) {
            this.sortDirection *= -1;
        } else {
            this.sortField = sortCb;
        }
    }

    public areaText(num: number = 0) {
        const { areas } = TEXTS.NETWORK;
        const category = this.ngLocalization.getPluralCategory(num, LANGUAGE);
        return [num, areas[category]].join(' ');
    }

    public postText(num: number = 0) {
        const { post } = TEXTS.NOTIFICATIONS;
        const category = this.ngLocalization.getPluralCategory(num, LANGUAGE);
        return [num, post[category]].join(' ');
    }
}
