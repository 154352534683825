import { Pipe, PipeTransform } from '@angular/core';
import { RunImpact } from '../service/api-model-impact';

import * as moment from 'moment-timezone';
import { formatTime } from '@cityair/modules/impact/utils';
import { formatDayMonth } from '@cityair/config';

@Pipe({ name: 'finishRunTime' })
export class FinishRunTimePipe implements PipeTransform {
    transform(run: RunImpact): string {
        const m = moment(run.finished_at || 0);
        return `${formatTime(m)} ${formatDayMonth(m)}`;
    }
}
