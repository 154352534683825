import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { NetworkActions, selectDomain } from '@cityair/modules/network/store/network.feature';
import { DATA_MODELING } from '@cityair/modules/network/constants';
import { getGridLineByBbox } from '@cityair/modules/network/utils';
import { selectAllPosts } from '@cityair/modules/core/store/posts/posts.feature';
import { selectMeasureScheme, selectMeasuresZones } from '@cityair/modules/core/store/selectors';
import { MeasureScheme } from '@libs/common/enums/measure-scheme';
import { NetworkMapData } from '@cityair/modules/network/service/api-models';

@Injectable()
export class NetworkEffects {
    constructor(private actions$: Actions, private store: Store) {}

    updateRunDate$ = createEffect(() =>
        this.actions$.pipe(
            ofType(NetworkActions.initModule),
            switchMap(() => [NetworkActions.setIsAvailable({ payload: true })])
        )
    );

    getDataModeling$ = createEffect(() =>
        this.actions$.pipe(
            ofType(NetworkActions.initModule),
            withLatestFrom(
                this.store.select(selectDomain),
                this.store.select(selectAllPosts),
                this.store.select(selectMeasuresZones),
                this.store.select(selectMeasureScheme)
            ),
            tap(() => this.store.dispatch(NetworkActions.setLoading({ payload: true }))),
            switchMap(([action, domain, posts, zones]) => {
                const actions = [];
                const scheme = MeasureScheme.default;
                const result = getGridLineByBbox(domain, DATA_MODELING, posts, zones[scheme]);
                const dataMap = result?.dataMap as NetworkMapData;
                actions.push(NetworkActions.setPostData({ payload: result?.postData }));
                actions.push(NetworkActions.setPriorityData({ payload: result?.priorityData }));
                actions.push(NetworkActions.setDataMap({ payload: dataMap }));
                actions.push(NetworkActions.setLoading({ payload: false }));
                return actions;
            })
        )
    );
}
