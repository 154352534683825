import { APP_INITIALIZER, enableProdMode, ErrorHandler, Injectable, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { CommonModule, registerLocaleData } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import localeRu from '@angular/common/locales/ru';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import * as Sentry from '@sentry/browser';
import { MetrikaModule } from 'ng-yandex-metrika';
import { Chart as ChartJS, registerables } from 'chart.js';
import { TimelinePanelModule } from '@libs/shared-ui/components/timeline-panel/timeline-panel.module';
import { DirectivesModule } from '@libs/shared-ui/components/directives/directives.module';
import { MapModule } from './modules/map/map.module';
import { CalendarModule } from '@cityair/libs/common/calendar/calendar.module';
import { CityscreenComponentsModule } from './components/cityscreen-components.module';
import { LittleComponentsModule } from '@libs/shared-ui/components/little-components/little-components.module';
import { GlobalMapModule } from './components/global-map/global-map.module';
import { CityScreenPage } from './cityScreenPage';

import { WindowGlobalVars } from '@cityair/namespace';
import { LoginModule } from './modules/login/login.module';
import { ClientConfigService } from './modules/core/services/client-config/client-config.service';
import { environment } from 'environments/environment';
import { httpInterceptorProviders } from './modules/core/services/api/interceptors';

import { PopupsModule } from './components/popups/popups.module';
import { SharedComponentsModule } from '@cityair/libs/shared/components/shared-components.module';
import { PipesModule } from '@libs/shared-ui/components/pipes/pipes.module';
import { TooltipsModule } from '@libs/shared-ui/components/tooltips/tooltips.module';
import { LoadersModule } from '@libs/shared-ui/components/spinner/loaders.module';
import { AqiDetailsTooltipModule } from '@libs/shared-ui/components/aqi-details-tooltip/aqi-details-tooltip.module';
import { SharedCoreModule } from '@cityair/libs/shared/modules/core/core.module';
import { MetrikaMockModule } from '@cityair/libs/shared/modules/metrika/metrika-mock.module';
import { commonReducers } from './modules/core/store/reducers';
import { CommonEffects } from './modules/core/store/effects';

import { CityscreenRoutingModule } from './cityscreen-routing.module';
import { MainPageComponent } from './pages/main-page/main-page.component';
import { ClientLoginPageComponent } from './pages/client-login-page/client-login-page.component';
import { IndoorModule } from './modules/indoor/indoor.module';
import { ReportsModule } from './modules/reports/reports.module';
import { ForecastModule } from './modules/forecast/forecast.module';
import { DefaultMapComponent } from './modules/map/components/default-map/default-map.component';
import { NotificationsMapComponent } from './modules/notifications/components/notifications-map/notifications-map.component';
import { DefaultMapMobileComponent } from './modules/map/components/default-map-mobile/default-map-mobile.component';
import { PlumesModule } from './modules/plumes/plumes.module';
import { SwitchItemModule } from '@libs/shared-ui/components/switch-item/switch-item.module';
import { SwitchButtonModule } from './components/switch-button/switch-button.module';
import { IndoorWidgetModule } from '@cityair/modules/indoor-widget/indoor-widget.module';
import { MmtMapSelectorComponentModule } from '@cityair/components/mmt-map-selector/mmt-map-selector.module';
import { ColorLegendModule } from '@cityair/components/color-legend/color-legend.module';
import { DownloadBtnModule } from '@cityair/libs/shared/components/download-btn/download-btn.module';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { cityCardFeature } from './modules/core/store/citycard/citycard.feature';
import { currentCityFeature } from './modules/core/store/current-city/current-city.feature';
import { modulePageConfigFeature } from './modules/core/store/module-page-config/module-page-config.feature';
import { SidebarToggleButtonModule } from '@libs/shared-ui/components/sidebar-toggle-button/sidebar-toggle-button.module';
import { SidebarMenuModule } from '@libs/shared-ui/components/sidebar-menu/sidebar-menu.module';
import { AnalyticsModule } from '@cityair/modules/analytics/analytics.module';
import { NotificationsModule } from '@cityair/modules/notifications/notifications.module';
import { PostsAndDevicesModule } from '@cityair/modules/posts-and-devices/posts-and-devices.module';
import { RouterModule } from '@angular/router';
import { FeedbackModule } from '@libs/shared-ui/components/feedback/feedback.module';
import { DataQualityModule } from '@cityair/components/data-quality/data-quality.module';
import { groupFeature } from '@cityair/modules/core/store/group/group.feature';
import { postsFeature } from '@cityair/modules/core/store/posts/posts.feature';
import { mapFeature } from '@cityair/modules/core/store/map/map.feature';
import { PostsEffects } from '@cityair/modules/core/store/posts/posts.effects';
import { AnalysisModule } from '@cityair/modules/analysis/analysis.module';
import { AnalysisEventsModule } from '@cityair/modules/analysis-events/analysis-events.module';
import { MapControlModule } from '@libs/shared-ui/components/map-control/map-control.module';
import { MmtSelectorModule } from '@libs/shared-ui/components/mmt-selector/mmt-selector.module';
import { SettingsModule } from '@cityair/modules/settings/settings.module';
import { publicForecastFeature } from '@cityair/modules/core/store/public-forecast/public-forecast.feature';
import { PublicForecastEffects } from '@cityair/modules/core/store/public-forecast/public-forecast.effects';
import { MapStyleSelectorComponent } from '@libs/shared-ui/components/map-style-selector/map-style-selector.component';
import { ImpactModule } from '@cityair/modules/impact/impact.module';
import { impactFeature } from '@cityair/modules/impact/store/impact.feature';
import { ImpactEffects } from '@cityair/modules/impact/store/impact.effects';
import { NetworkModule } from '@cityair/modules/network/network.module';
import { networkFeature } from '@cityair/modules/network/store/network.feature';
import { NetworkEffects } from '@cityair/modules/network/store/network.effects';

declare const window: WindowGlobalVars;

if (environment.production) {
    enableProdMode();
}

if (environment.sentry) {
    Sentry.init({
        dsn: 'https://20cf356d4cdb465daba339a2de5db292@o204789.ingest.sentry.io/5522834',
    });

    window.sentryCaptureException = Sentry.captureException;
    window.sentryAddBreadcrumb = Sentry.addBreadcrumb;
    window.sentryCaptureMessage = Sentry.captureMessage;
}

registerLocaleData(localeRu);
ChartJS.register(...registerables);
@Injectable()
export class SentryErrorHandler implements ErrorHandler {
    handleError(error: any) {
        Sentry.captureException(error.originalError || error);
        console.error(error);
    }
}

function clientConfigFactory(clientConfigService: ClientConfigService) {
    return () => clientConfigService.loadFromGlobalsLegacy();
}

@NgModule({
    imports: [
        environment.yandex_metrika
            ? MetrikaModule.forRoot(environment.yandex_metrika)
            : MetrikaMockModule,
        BrowserModule,
        RouterModule,
        CommonModule,
        FormsModule,
        CityscreenRoutingModule,
        HttpClientModule,
        ReactiveFormsModule,
        DirectivesModule,
        PipesModule,
        SharedCoreModule,
        MapModule,
        CalendarModule,
        LittleComponentsModule,
        CityscreenComponentsModule,
        SidebarMenuModule,
        TooltipsModule,
        PopupsModule,
        SharedComponentsModule,
        LoadersModule,
        LoginModule,
        SharedComponentsModule,
        StoreModule.forRoot({ core: commonReducers }),
        StoreModule.forFeature(cityCardFeature),
        StoreModule.forFeature(currentCityFeature),
        StoreModule.forFeature(modulePageConfigFeature),
        StoreModule.forFeature(groupFeature),
        StoreModule.forFeature(postsFeature),
        StoreModule.forFeature(mapFeature),
        StoreModule.forFeature(publicForecastFeature),
        StoreModule.forFeature(impactFeature),
        StoreModule.forFeature(networkFeature),
        EffectsModule.forRoot([
            CommonEffects,
            PostsEffects,
            PublicForecastEffects,
            ImpactEffects,
            NetworkEffects,
        ]),
        /* StoreDevtoolsModule.instrument({
            maxAge: 60,
        }), */
        IndoorModule,
        ReportsModule,
        ForecastModule,
        TimelinePanelModule,
        SwitchItemModule,
        DownloadBtnModule,
        SwitchButtonModule,
        AqiDetailsTooltipModule,
        GlobalMapModule,
        IndoorWidgetModule,
        MmtMapSelectorComponentModule,
        ColorLegendModule,
        SidebarToggleButtonModule,
        CalendarModule,
        AnalyticsModule,
        NotificationsModule,
        PlumesModule,
        PostsAndDevicesModule,
        FeedbackModule,
        DataQualityModule,
        AnalysisModule,
        AnalysisEventsModule,
        MapControlModule,
        MmtSelectorModule,
        SettingsModule,
        MapStyleSelectorComponent,
        ImpactModule,
        NetworkModule,
    ],
    providers: [
        {
            provide: APP_INITIALIZER,
            useFactory: clientConfigFactory,
            deps: [ClientConfigService],
            multi: true,
        },
        httpInterceptorProviders,
        {
            provide: ErrorHandler,
            useClass: SentryErrorHandler,
        },
    ],
    declarations: [
        CityScreenPage,
        ClientLoginPageComponent,
        MainPageComponent,
        DefaultMapComponent,
        DefaultMapMobileComponent,
        NotificationsMapComponent,
    ],
    bootstrap: [MainPageComponent],
})
export class CityScreenModule {}
