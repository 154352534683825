import type { BBox } from 'geojson';
import {
    NetworkDataModeling,
    NetworkMapData,
    NetworkPolygonData,
    NetworkPostData,
    NetworkPriorityData,
    PostData,
    PriorityData,
    ScaleInfoNetwork,
} from './service/api-models';
import { getAssetPath } from '@cityair/config';
import { Post } from '@libs/common/models/basicModels';
import { ColorZone } from '@libs/common/types/color-zone';
import { getColorFromZone } from '@cityair/utils/utils';
import { MeasuresZones } from '@libs/common/types/measures-zones';
import { LABEL_VALUE, PRIORITY_VALUE } from '@cityair/modules/network/constants';

export function getGridLineByBbox(
    bbox: BBox,
    data: NetworkDataModeling,
    posts: Post[],
    zones: MeasuresZones
): { dataMap: NetworkMapData; priorityData: NetworkPriorityData; postData: NetworkPostData } {
    const scales: ScaleInfoNetwork[] = [];
    const latStep = (bbox[3] - bbox[1]) / 25;
    const lngStep = (bbox[2] - bbox[0]) / 21;
    const scalesVert: ScaleInfoNetwork = {
        id: 'network-vert-scale',
        url: getAssetPath('network/vertical-scale.png'),
        coordinates: [
            [bbox[0] - (lngStep * 2) / 3, bbox[3]],
            [bbox[0], bbox[3]],
            [bbox[0], bbox[1]],
            [bbox[0] - (lngStep * 2) / 3, bbox[1]],
        ],
    };
    scales.push(scalesVert);
    const scalesHor: ScaleInfoNetwork = {
        id: 'network-hor-scale',
        url: getAssetPath('network/horizontal-scale.png'),
        coordinates: [
            [bbox[0], bbox[3] + (latStep * 2) / 3],
            [bbox[2], bbox[3] + (latStep * 2) / 3],
            [bbox[2], bbox[3]],
            [bbox[0], bbox[3]],
        ],
    };

    scales.push(scalesHor);
    const keys = Object.keys(data);
    const dataMap: NetworkMapData = {
        PM25: { polygon: null, scales: null },
        CO: { polygon: null, scales: null },
        NO2: { polygon: null, scales: null },
    };
    const priorityData: NetworkPriorityData = {
        PM25: [],
        CO: [],
        NO2: [],
    };

    const postData: NetworkPostData = {
        PM25: [],
        CO: [],
        NO2: [],
    };
    keys.forEach((mmt) => {
        const priorityValue = PRIORITY_VALUE[mmt];
        const prepareData = getDataByMmt(bbox, data[mmt], posts, zones[mmt], priorityValue);
        const polygonData: NetworkPolygonData = {
            polygon: prepareData.polygon,
            scales: scales,
        };
        dataMap[mmt] = polygonData;
        priorityData[mmt] = prepareData.priorityData;
        postData[mmt] = prepareData.postData;
    });

    return { dataMap, priorityData, postData };
}

function getDataByMmt(
    bbox: BBox,
    data: number[][],
    posts: Post[],
    zones: ColorZone,
    priorityValue: number
): { polygon: GeoJSON.FeatureCollection; priorityData: PriorityData[]; postData: PostData[] } {
    const polygon: GeoJSON.FeatureCollection = {
        type: 'FeatureCollection',
        features: [],
    };

    const priorityData: PriorityData[] = [];
    const postData: PostData[] = [];
    const latStep = (bbox[3] - bbox[1]) / 25;
    const lngStep = (bbox[2] - bbox[0]) / 21;
    let i = 0;
    for (let lng = bbox[0]; lng <= bbox[2] - lngStep; lng += lngStep) {
        let k = 0;
        for (let lat = bbox[1]; lat < bbox[3]; lat += latStep) {
            const value = data[i][k] ?? null;
            const coordinates = [
                [
                    [lng, lat],
                    [lng, lat + latStep],
                    [lng + lngStep, lat + latStep],
                    [lng + lngStep, lat],
                    [lng, lat],
                ],
            ];
            const insidePostIds = getInsidePosts(posts, coordinates[0]);
            const postIds = insidePostIds.map((v) => v.id);

            const color = getColorFromZone(zones, data[i][k]);
            polygon.features.push({
                type: 'Feature',
                geometry: {
                    type: 'Polygon',
                    coordinates,
                },
                properties: {
                    value,
                    color,
                    opacity: 0.5,
                    border: '#6C7484',
                },
            });
            if (value > priorityValue) {
                const priority: PriorityData = {
                    name: `${LABEL_VALUE[k]}${i}`,
                    region: null,
                    postIds,
                    value,
                    color,
                };
                priorityData.push(priority);
            }
            if (insidePostIds.length) {
                insidePostIds.forEach((post) => {
                    postData.push({
                        id: post.id,
                        region: null,
                        value,
                        color,
                    });
                });
            }
            k++;
        }
        i++;
    }
    return { polygon, priorityData, postData };
}

function getInsidePosts(posts, coordinate): Post[] {
    const bbox = [coordinate[0][0], coordinate[0][1], coordinate[2][0], coordinate[1][1]];
    const insidePostIds = posts.filter((v) => {
        const pt = v.geometry.coordinates;
        if (bbox[0] <= pt[0] && bbox[1] <= pt[1] && bbox[2] >= pt[0] && bbox[3] >= pt[1]) {
            return v;
        }
    });

    return insidePostIds.length ? insidePostIds : [];
}
