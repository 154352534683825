import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { EntityDataModule } from '@ngrx/data';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { CalendarModule } from '@cityair/libs/common/calendar/calendar.module';
import { CalendarMonthsModule } from '@cityair/libs/common/calendar/calendar-months/calendar-months.module';
import { LittleComponentsModule } from '@libs/shared-ui/components/little-components/little-components.module';
import { PipesModule } from '@libs/shared-ui/components/pipes/pipes.module';
import { DirectivesModule } from '@libs/shared-ui/components/directives/directives.module';
import { TooltipsModule } from '@libs/shared-ui/components/tooltips/tooltips.module';
import { SharedComponentsModule } from '@cityair/libs/shared/components/shared-components.module';
import { PageTabsModule } from '@libs/shared-ui/components/page-tabs/page-tabs.module';
import { CardModule } from '@cityair/components/card/card.module';
import { CardActionsModule } from '@cityair/components/card-actions/card-actions.module';
import { InputModule } from '@libs/shared-ui/components/input/input.module';
import { InputForCoordinateModule } from '@cityair/components/input-for-coordinate/input-for-coordinate.module';
import { LoadersModule } from '@libs/shared-ui/components/spinner/loaders.module';
import { ButtonAddModule } from '@libs/shared-ui/components/button-add/button-add.module';
import { CityscreenComponentsModule } from '@cityair/components/cityscreen-components.module';
import { OverlayModule } from '@cityair/libs/shared/modules/overlay/overlay.module';
import { SwitchItemModule } from '@libs/shared-ui/components/switch-item/switch-item.module';
import { KebabMenuModule } from '@cityair/libs/shared/components/kebab-menu/kebab-menu.module';
import { ColorLegendModule } from '@cityair/components/color-legend/color-legend.module';
import { SwitchButtonModule } from '@cityair/components/switch-button/switch-button.module';

import { DownloadBtnModule } from '@cityair/libs/shared/components/download-btn/download-btn.module';
import { AqiDetailsTooltipModule } from '@libs/shared-ui/components/aqi-details-tooltip/aqi-details-tooltip.module';
import { TimelinePanelModule } from '@libs/shared-ui/components/timeline-panel/timeline-panel.module';
import { ButtonModule } from '@libs/shared-ui/components/button/button.module';
import { ButtonsSaveCancelModule } from '@libs/shared-ui/components/buttons-save-cancel/buttons-save-cancel.module';
import { MapControlModule } from '@libs/shared-ui/components/map-control/map-control.module';
import { MmtSelectorModule } from '@libs/shared-ui/components/mmt-selector/mmt-selector.module';
import { HeightsSelectorModule } from '@libs/shared-ui/components/heights-selector/heights-selector.module';
import { InputForDropdownModule } from '@libs/shared-ui/components/input-for-dropdown/input-for-dropdown.module';
import { InputDropdownModule } from '@libs/shared-ui/components/input-dropdown/input-dropdown.module';
import { SelectboxRadioModule } from '@libs/shared-ui/components/selectbox-radio/selectbox-radio.module';
import { MapStyleSelectorComponent } from '@libs/shared-ui/components/map-style-selector/map-style-selector.component';
import { ImpactMainComponent } from '@cityair/modules/impact/components/impact-main.component/impact-main.component';
import { ControlPointsComponent } from '@cityair/modules/impact/components/impact-main.component/control-points/control-points.component';
import { ImpactRunsComponent } from '@cityair/modules/impact/components/impact-main.component/impact-runs/impact-runs.component';
import { ImpactRunResultsComponent } from '@cityair/modules/impact/components/impact-main.component/impact-run-results/impact-run-results.component';
import { ImpactMapElementComponent } from '@cityair/modules/impact/components/impact-map.component/impact-map-element/impact-map-element.component';
import { ImpactMapComponent } from '@cityair/modules/impact/components/impact-map.component/impact-map.component';
import { ImpactRunTimePipe } from '@cityair/modules/impact/pipes/run-time.pipe';
import { FinishRunTimePipe } from '@cityair/modules/impact/pipes/finish-run-time.pipe';
import { ConfigRunCardComponent } from '@cityair/modules/impact/components/impact-main.component/impact-runs/config-run-card/config-run-card.component';
import { DurationsRunPipe } from '@cityair/modules/impact/pipes/config-duration.pipe';
import { ConfigPeriodTimePipe } from '@cityair/modules/impact/pipes/config-period-time.pipe';
import { ImpactEditRunComponent } from '@cityair/modules/impact/components/impact-main.component/impact-runs/impact-edit-run/impact-edit-run.component';
import { ImpactRunDropdownListComponent } from '@cityair/modules/impact/components/impact-main.component/control-points/impact-runs-dropdown-list/impact-run-dropdown-list.component';
import { ImpactRunResultComponent } from '@cityair/modules/impact/components/impact-main.component/impact-run-result/impact-run-result.component';
import { ImpactControlPointComponent } from '@cityair/modules/impact/components/impact-main.component/control-points/impact-control-point/impact-control-point.component';
import { NonDataScreenModule } from '@cityair/components/non-data-screen/non-data-screen.module';
@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        FormsModule,
        ReactiveFormsModule,
        PipesModule,
        DirectivesModule,
        LittleComponentsModule,
        CardModule,
        TooltipsModule,
        ButtonAddModule,
        CalendarModule,
        SharedComponentsModule,
        PageTabsModule,
        CardActionsModule,
        InputModule,
        InputForCoordinateModule,
        CalendarMonthsModule,
        LoadersModule,
        CityscreenComponentsModule,
        OverlayModule,
        TimelinePanelModule,
        DownloadBtnModule,
        SwitchItemModule,
        AqiDetailsTooltipModule,
        KebabMenuModule,
        ColorLegendModule,
        ButtonModule,
        ButtonsSaveCancelModule,
        SwitchButtonModule,
        MapControlModule,
        MmtSelectorModule,
        HeightsSelectorModule,
        InputForDropdownModule,
        InputDropdownModule,
        SelectboxRadioModule,
        MapStyleSelectorComponent,
        NonDataScreenModule,
    ],
    exports: [ImpactMapComponent],
    declarations: [
        ImpactMainComponent,
        ControlPointsComponent,
        ImpactRunsComponent,
        ImpactRunResultsComponent,
        ImpactMapComponent,
        ImpactMapElementComponent,
        ImpactRunTimePipe,
        FinishRunTimePipe,
        ConfigRunCardComponent,
        DurationsRunPipe,
        ConfigPeriodTimePipe,
        ImpactEditRunComponent,
        ImpactRunDropdownListComponent,
        ImpactRunResultComponent,
        ImpactControlPointComponent,
    ],
})
export class ImpactModule {}
