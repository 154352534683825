import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { TEXTS } from '@libs/common/texts/texts';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { TabModel } from '@libs/common/types/tab-model';

import { IMPACT_PAGES, IMPACTS_INIT_PAGE } from '@cityair/modules/impact/models';
import { selectUserRoleId } from '@cityair/modules/core/store/selectors';
import { filter, take, takeUntil } from 'rxjs/operators';
import { OBSERVER_ID, OPERATOR_ID } from '@cityair/config';
import { MAIN_PAGES } from '@libs/common/enums/main-pages';
import {
    ImpactActions,
    selectActiveTab,
    selectRunsLoadError,
} from '@cityair/modules/impact/store/impact.feature';
import { getCurrentGroup } from '@cityair/modules/core/store/group/group.feature';
import { VangaAuthService } from '@cityair/modules/core/services/vanga-auth/vanga-auth.service';
import { refreshVangaToken } from '@cityair/modules/core/store/actions';

@Component({
    selector: 'cityair-impact-main',
    templateUrl: './impact-main.component.html',
    styleUrls: ['./impact-main.component.less'],
})
export class ImpactMainComponent implements OnInit, OnDestroy {
    public translateText = TEXTS.IMPACT;
    public title = TEXTS.ADMIN_PANEL.impact;
    public errorListLoadText = TEXTS.FORECAST.errorListLoad;
    public ngDestroyed$ = new Subject<void>();
    public isOperator = false;
    readonly mainComponentUrl = `/${MAIN_PAGES.impact}/`;

    tabs: TabModel[] = [
        {
            isDisabled: false,
            type: 1,
            id: IMPACT_PAGES.runResults,
            title: TEXTS.PLUMES.runResults,
        },
    ];
    selectedTab: TabModel = null;
    activeTab: string;
    selectRunsLoadError = selectRunsLoadError;
    constructor(
        private router: Router,
        public store: Store,
        private vangaAuthService: VangaAuthService,
        private _cdRef: ChangeDetectorRef
    ) {
        let isValidToken = true;
        this.store.dispatch(ImpactActions.setIsAvailable({ payload: true }));
        const token = this.vangaAuthService.getAccessToken();
        if (!token || token === 'null') {
            isValidToken = false;
        }
        this.store
            .select(getCurrentGroup)
            .pipe(takeUntil(this.ngDestroyed$))
            .subscribe(async (group) => {
                if (group) {
                    if (isValidToken) {
                        this.store.dispatch(ImpactActions.initModule());
                    } else {
                        this.store.dispatch(refreshVangaToken());
                    }
                }
            });
    }

    ngOnInit(): void {
        this.store
            .select(selectUserRoleId)
            .pipe(
                takeUntil(this.ngDestroyed$),
                filter((roleId) => !!roleId)
            )
            .subscribe((roleId) => {
                if (roleId !== OBSERVER_ID.toString()) {
                    this.isOperator = roleId === OPERATOR_ID.toString();
                    if (this.tabs.length === 1) {
                        this.addTabs();
                        this.selectedTab = this.tabs.find((item) => item.id == this.activeTab);
                        this._cdRef.detectChanges();
                    }
                }
            });
        this.store
            .select(selectActiveTab)
            .pipe(
                filter((v) => v !== null),
                take(1)
            )
            .subscribe((tab) => {
                if (this.router.url === `/${MAIN_PAGES.impact}`) {
                    if (!tab) {
                        this.openPage({ id: IMPACTS_INIT_PAGE });
                    } else {
                        this.openPage({ id: tab });
                    }
                } else {
                    this.activeTab = tab;
                    const selectedTab = this.tabs.find((item) => item.id == this.activeTab);
                    if (selectedTab) {
                        this.selectedTab = selectedTab;
                        this._cdRef.detectChanges();
                    }
                }
            });
    }

    ngOnDestroy(): void {
        this.store.dispatch(ImpactActions.setIsAvailable({ payload: false }));
        this.ngDestroyed$.next();
    }

    openPage(event: TabModel) {
        this.selectedTab = this.tabs.find((item) => item.id === event.id);
        this.router.navigate([this.mainComponentUrl + event.id]);
    }

    private addTabs(): void {
        this.tabs.push({
            title: TEXTS.PLUMES.runConfiguration,
            type: 2,
            id: IMPACT_PAGES.configs,
        });
        this.tabs.push({
            title: TEXTS.PLUMES.controlPoints,
            type: 3,
            id: IMPACT_PAGES.controlPoints,
        });
    }
}
