<div class="impact_run_result scroll">
    <div class="impact_run_result__date-filter">

        <div class="calendar-filter"
             (clickOutside)="calendarIsOpened = false"
        >
            <div (click)="toggleCalendar()">
                <input-for-dropdown
                    [label]="labelPeriod"
                    [inputValue]="dateRange | dateRangeText"
                    [(isOpenDropdown)]="calendarIsOpened"
                ></input-for-dropdown>
            </div>
            <div class="impact_run_result__calendar">
                <calendar-months
                    [show]="calendarIsOpened"
                    [timeBegin]="dateTimeBegin"
                    [timeEnd]="dateTimeEnd"
                    [applyCb]="updateDateRange"
                    [monthsVisible]="1"
                    [updateDays]="updateDays"
                    [columns]="1"
                    [numberOfMonths]="12"
                ></calendar-months>
            </div>
        </div>
    </div>
    <div *ngIf="store.select(selectIsLoading) | async else contentTemp">
        <div class="wrapper-loading">
            <ca-spinner [color]="'#BFE2F8'"></ca-spinner>
        </div>
    </div>
    <ng-template #contentTemp>
        <ng-container *ngIf="runsEmpty; else runsContent">
            <non-data-screen
                class="screen-placeholders"
                [text]="textNoRunsAvailable"
            ></non-data-screen>
        </ng-container>
        <ng-template #runsContent>
            <div class="impact_run_result__content scroll">
                <ng-container *ngFor="let run of runs">
                    <div class="impact_run_result__annotation" *ngIf="run.annotation">
                        {{ run.annotation }}
                    </div>
                    <div class="impact_run_result__card_wrapper" (click)="selectRun(run.item)">
                        <div class="impact_run_result__card"
                             [class.impact_run_result__card-active]="run.item.id === currentRunId"
                        >
                            <div *ngIf="run.item.id === currentRunId" class="impact_run_result__card__is_now">
                                {{ nowOnMapText }}
                            </div>

                            <ca-spinner *ngIf="run.item.id === currentRunId && (store.select(selectIsLoadingRun) | async)"
                                class="impact_run_result__spin" color="#6097F3"
                            ></ca-spinner>
                                 <ca-impact-run-result
                                    class="plumes_calc__wrapper_run_summary"
                                    [run]="run.item"
                                    [config]="store.select(selectConfigById(run.item?.config)) | async"
                                ></ca-impact-run-result>
                        </div>
                    </div>
                </ng-container>
            </div>
        </ng-template>
    </ng-template>
</div>
