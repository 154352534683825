import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';

import { TEXTS } from '@libs/common/texts/texts';
import { Dictionary } from '@ngrx/entity';
import { sortMeasurements } from '@libs/common/utils/utils';
import { SourceImpact } from '@cityair/modules/impact/service/api-model-impact';

@Component({
    selector: 'ca-config-run-card',
    templateUrl: 'config-run-card.component.html',
    styleUrls: ['config-run-card.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConfigRunCardComponent implements OnChanges {
    @Input() minutes: number;
    @Input() durations: number;
    @Input() valueTypes: string[];
    @Input() windIsOn: boolean;
    @Input() sources: number[];
    @Input() sourcesSnapshot: Dictionary<SourceImpact>;

    textsPlumes = TEXTS.PLUMES;
    textNames = TEXTS.NAMES;
    textShowList = TEXTS.PLUMES.showSources;

    isShowSourcesList = false;
    mmts: string[] = [];

    ngOnChanges(changes: SimpleChanges) {
        if (changes?.valueTypes?.currentValue) {
            const mmts = [...this.valueTypes].sort(sortMeasurements);
            this.mmts = mmts.map((item) => this.textNames[item] ?? item);
        }
    }
}
