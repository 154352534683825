import {
    createFeature,
    createReducer,
    createSelector,
    createActionGroup,
    props,
    on,
    createFeatureSelector,
    emptyProps,
    select,
} from '@ngrx/store';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { IMPACT_PAGES } from '@cityair/modules/impact/models';
import { DateRange, sourcesAdapter } from '@cityair/modules/plumes/store/reducers';
import {
    ControlPointImpact,
    ControlPointTimeline,
    DateRangeImpact,
    RunConfigImpact,
    RunConfigImpactEdit,
    RunImpact,
    SourceImpact,
} from '@cityair/modules/impact/service/api-model-impact';
import { DATE_FOR_CITY, INIT_IMPACT_DATE } from '@cityair/modules/impact/consts';
import { selectGroupId } from '@cityair/modules/core/store/group/group.feature';
import { selectMeasureScheme, selectMeasuresZones } from '@cityair/modules/core/store/selectors';
import * as moment from 'moment-timezone';
import { getIntervalByRun } from '@cityair/modules/plumes/services/station/models';
import { MeasureScheme } from '@libs/common/enums/measure-scheme';
import { ColorZone } from '@libs/common/types/color-zone';
import { isFalseNumber } from '@libs/common/utils/utils';
import { getVangaScheme } from '@cityair/modules/impact/utils';
import { HttpParamsOptions } from '@angular/common/http';
import { Feature } from '@libs/shared-ui/components/timeline-panel/models/core';
import { NEW_CONTROL_POINT_OBJ_TYPE } from '@cityair/modules/plumes/constants';
import { ControlPoint } from '@cityair/modules/plumes/services/control-point/models';
import { pipe } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import { TilesUpdateParams } from '@cityair/modules/plumes/services/plumes-tiles-player/plumes-tiles-player';
import { selectCurrentCity } from '@cityair/modules/core/store/current-city/current-city.feature';
import { QueryParams } from '@ngrx/data';
import { IBasicResponse } from '@libs/common/models/basicModels';

const IMPACT_FEATURE_KEY = 'impact';
export const impactRunAdapter: EntityAdapter<RunImpact> = createEntityAdapter<RunImpact>();
export const impactSourcesAdapter: EntityAdapter<SourceImpact> =
    createEntityAdapter<SourceImpact>();
export const impactConfigAdapter: EntityAdapter<RunConfigImpact> =
    createEntityAdapter<RunConfigImpact>();
export const controlPointImpactAdapter: EntityAdapter<ControlPointImpact> =
    createEntityAdapter<ControlPointImpact>();
export const controlPointTimelineAdapter: EntityAdapter<ControlPointTimeline> =
    createEntityAdapter<ControlPointTimeline>({
        selectId: (point: ControlPointTimeline) => point.control_point,
    });
export const ImpactActions = createActionGroup({
    source: 'Impact',
    events: {
        'Init Module': emptyProps(),
        'Set Is Available': props<{ payload: boolean }>(),
        'Set Active Tab': props<{ payload: IMPACT_PAGES }>(),
        'Toggle Layer Map': props<{ payload: boolean }>(),
        'Set Loading': props<{ payload: boolean }>(),
        'Update Date Range Run': props<{ payload: DateRange }>(),
        'Set Run Load Error': props<{ payload: boolean }>(),
        'Clear Runs': emptyProps(),
        'Set Runs': props<{ payload: RunImpact[] }>(),
        'Set Run Configs': props<{ payload: RunConfigImpact[] }>(),
        'Set Run Config': props<{ payload: RunConfigImpact }>(),
        'Update Run Config': props<{ payload: RunConfigImpactEdit }>(),
        'Set Sources': props<{ payload: SourceImpact[] }>(),
        'Set Loading Edit Run': props<{ payload: boolean }>(),
        'Set Success Edit Run': props<{ payload: boolean }>(),
        'Set Error Edit Run': props<{ payload: any }>(),
        'Set Active Run': props<{ payload: RunImpact }>(),
        'Set Active Mmt': props<{ payload: string }>(),
        'Toggle Wind Layer': props<{ payload: boolean }>(),
        'Set Is Disable Wind Button': props<{ payload: boolean }>(),
        'Toggle Display Station Pin': props<{ payload: boolean }>(),
        'Set Active Height': props<{ payload: number }>(),
        'Set Time Index': props<{ payload: number }>(),
        'Set Control Points': props<{ payload: ControlPointImpact[] }>(),
        'Set Control Points Timeline': props<{ payload: ControlPointTimeline[] }>(),
        'Set Control Points Is Loading': props<{ payload: boolean }>(),
        'Set Dates': props<{ payload: string[] }>(),
        'Set Sources Order': props<{ payload: number[] }>(),
        'Expand Pin': props<{ payload: any }>(),
        'Set Edit Control Point': props<{ payload: ControlPointImpact }>(),
        'Set Active Pin': props<{ payload: any }>(),
        'Set Chart Data': props<{ payload: Feature[] }>(),
        'Set City Data': props<{ payload: IBasicResponse }>(),
    },
});
interface ImpactState {
    isActiveImpact: boolean;
    activeTab: IMPACT_PAGES;
    isLoading: boolean;
    showLayerOnMap: boolean;
    dateRangeImpactRuns: DateRangeImpact;
    impactTimeIndex: number;
    runsLoadError: boolean;
    isLoadingRun: boolean;
    isLoadingControlPoints: boolean;
    isLoadingEditRun: boolean;
    successEditRun: boolean;
    errorEditRun: any;
    showWindOnMap: boolean;
    disableWindButton: boolean;
    expandControlPoint: string;
    displayPinsOnMap: boolean;
    isLoadingContributionData: boolean;
    errorContributionData: boolean;
    runs: EntityState<RunImpact>;
    configs: EntityState<RunConfigImpact>;
    sources: EntityState<SourceImpact>;
    activeRun: RunImpact;
    activeMmt: string;
    activeHeight: number;
    controlPoints: EntityState<ControlPointImpact>;
    controlPointsTimeline: EntityState<ControlPointTimeline>;
    dates: string[];
    sourcesOrder: number[];
    chartData: Feature[];
    activePin: any;
    expandPin: string;
    editControlPoint: ControlPointImpact;
    currentCityData: IBasicResponse;
}
const initialState: ImpactState = {
    isActiveImpact: false,
    activeTab: null,
    isLoading: true,
    showLayerOnMap: true,
    dateRangeImpactRuns: INIT_IMPACT_DATE,
    impactTimeIndex: 0,
    runsLoadError: false,
    isLoadingRun: false,
    isLoadingControlPoints: false,
    isLoadingEditRun: false,
    successEditRun: false,
    errorEditRun: null,
    showWindOnMap: false,
    disableWindButton: false,
    expandControlPoint: null,
    displayPinsOnMap: true,
    isLoadingContributionData: false,
    errorContributionData: false,
    runs: impactRunAdapter.getInitialState(),
    configs: impactConfigAdapter.getInitialState(),
    sources: impactSourcesAdapter.getInitialState(),
    activeRun: null,
    activeMmt: null,
    activeHeight: null,
    controlPoints: controlPointImpactAdapter.getInitialState(),
    controlPointsTimeline: controlPointTimelineAdapter.getInitialState(),
    dates: [],
    sourcesOrder: [],
    chartData: [],
    activePin: null,
    expandPin: null,
    editControlPoint: null,
    currentCityData: null,
};

const impactSelector = createFeatureSelector<ImpactState>(IMPACT_FEATURE_KEY);
export const selectRunsImpact = createSelector(impactSelector, (state) =>
    impactRunAdapter.getSelectors().selectAll(state.runs)
);
export const selectSourcesImpact = createSelector(impactSelector, (state) =>
    impactSourcesAdapter.getSelectors().selectEntities(state.sources)
);
export const selectConfigImpact = createSelector(impactSelector, (state) =>
    impactConfigAdapter.getSelectors().selectAll(state.configs)
);
export const selectConfigImpactDic = createSelector(impactSelector, (state) =>
    impactConfigAdapter.getSelectors().selectEntities(state.configs)
);
export const selectControlPoints = createSelector(impactSelector, (state) =>
    controlPointImpactAdapter.getSelectors().selectAll(state.controlPoints)
);
export const selectControlPointsDic = createSelector(impactSelector, (state) =>
    controlPointImpactAdapter.getSelectors().selectEntities(state.controlPoints)
);
export const selectControlPointsTimeline = createSelector(impactSelector, (state) =>
    controlPointTimelineAdapter.getSelectors().selectAll(state.controlPointsTimeline)
);
export const selectControlPointsTimelineDic = createSelector(impactSelector, (state) =>
    controlPointTimelineAdapter.getSelectors().selectEntities(state.controlPointsTimeline)
);
export const selectControlPointsTimelineById = (id) => {
    createSelector(selectControlPointsTimelineDic, (points) => points?.[id] ?? null);
};
export const impactFeature = createFeature({
    name: IMPACT_FEATURE_KEY,
    reducer: createReducer(
        initialState,
        on(ImpactActions.setIsAvailable, (state, { payload }) => ({
            ...state,
            isActiveImpact: payload,
        })),
        on(ImpactActions.setActiveTab, (state, { payload }) => ({
            ...state,
            activeTab: payload,
        })),
        on(ImpactActions.setLoading, (state, { payload }) => ({
            ...state,
            isLoading: payload,
        })),
        on(ImpactActions.setRunLoadError, (state, { payload }) => ({
            ...state,
            runsLoadError: payload,
        })),
        on(ImpactActions.updateDateRangeRun, (state, { payload }) => ({
            ...state,
            dateRangeImpactRuns: payload,
        })),
        on(ImpactActions.clearRuns, (state) => ({
            ...state,
            runs: impactRunAdapter.removeAll(state.runs),
        })),
        on(ImpactActions.setRuns, (state, { payload }) => {
            const runsResponse = payload as RunImpact[];

            const runs = impactRunAdapter.setMany(runsResponse, state.runs);
            return {
                ...state,
                runs,
            };
        }),
        on(ImpactActions.setControlPoints, (state, { payload }) => {
            const controlPointsResponse = payload as ControlPointImpact[];

            const controlPoints = controlPointImpactAdapter.setMany(
                controlPointsResponse,
                state.controlPoints
            );
            return {
                ...state,
                controlPoints,
            };
        }),
        on(ImpactActions.setRunConfigs, (state, { payload }) => {
            const runsResponse = payload as RunConfigImpact[];
            const configs = impactConfigAdapter.setMany(runsResponse, state.configs);
            return {
                ...state,
                configs,
            };
        }),
        on(ImpactActions.setRunConfig, (state, { payload }) => {
            const configs = impactConfigAdapter.setOne(payload, state.configs);
            return { ...state, configs };
        }),
        on(ImpactActions.setSources, (state, { payload }) => {
            const sources = sourcesAdapter.setMany(payload, state.sources);
            return {
                ...state,
                sources,
            };
        }),
        on(ImpactActions.setLoadingEditRun, (state, { payload }) => ({
            ...state,
            isLoadingEditRun: payload,
        })),
        on(ImpactActions.setSuccessEditRun, (state, { payload }) => ({
            ...state,
            successEditRun: payload,
        })),
        on(ImpactActions.setErrorEditRun, (state, { payload }) => ({
            ...state,
            errorEditRun: payload,
        })),
        on(ImpactActions.setActiveRun, (state, { payload }) => {
            let activeMmt = state.activeMmt;
            if (!activeMmt || (activeMmt && !payload?.species_list.includes(activeMmt))) {
                activeMmt = payload?.default_species;
            }
            let activeHeight = state.activeHeight;
            if (!activeHeight || (activeHeight && !payload?.heights.includes(activeHeight))) {
                activeHeight = payload?.heights?.[0] ?? null;
            }
            return {
                ...state,
                activeRun: payload,
                activeMmt,
                activeHeight,
            };
        }),
        on(ImpactActions.setActiveMmt, (state, { payload }) => ({
            ...state,
            activeMmt: payload,
        })),
        on(ImpactActions.setActiveHeight, (state, { payload }) => ({
            ...state,
            activeHeight: payload,
        })),
        on(ImpactActions.toggleLayerMap, (state, { payload }) => ({
            ...state,
            showLayerOnMap: payload === null ? !state.showLayerOnMap : payload,
        })),
        on(ImpactActions.toggleWindLayer, (state, { payload }) => ({
            ...state,
            showWindOnMap: payload === null ? !state.showWindOnMap : payload,
        })),
        on(ImpactActions.toggleDisplayStationPin, (state, { payload }) => ({
            ...state,
            displayPinsOnMap: payload === null ? !state.displayPinsOnMap : payload,
        })),
        on(ImpactActions.setControlPointsIsLoading, (state, { payload }) => ({
            ...state,
            isLoadingControlPoints: payload,
        })),
        on(ImpactActions.setTimeIndex, (state, { payload }) => ({
            ...state,
            impactTimeIndex: payload,
        })),
        on(ImpactActions.setDates, (state, { payload }) => ({
            ...state,
            dates: payload,
        })),
        on(ImpactActions.setSourcesOrder, (state, { payload }) => ({
            ...state,
            sourcesOrder: payload,
        })),
        on(ImpactActions.setControlPointsTimeline, (state, { payload }) => {
            const response = payload as ControlPointTimeline[];
            const controlPointsTimeline = controlPointTimelineAdapter.setMany(
                response,
                state.controlPointsTimeline
            );
            return {
                ...state,
                controlPointsTimeline,
            };
        }),
        on(ImpactActions.expandPin, (state, { payload }) => ({
            ...state,
            expandPin: payload,
        })),
        on(ImpactActions.setEditControlPoint, (state, { payload }) => ({
            ...state,
            editControlPoint: payload,
        })),
        on(ImpactActions.setActivePin, (state, { payload }) => ({
            ...state,
            activePin: payload,
        })),
        on(ImpactActions.setChartData, (state, { payload }) => ({
            ...state,
            chartData: payload,
        })),
        on(ImpactActions.setCityData, (state, { payload }) => ({
            ...state,
            currentCityData: payload,
        }))
    ),
    extraSelectors: ({
        selectDateRangeImpactRuns,
        selectActiveMmt,
        selectActiveRun,
        selectActiveHeight,
        selectDates,
        selectImpactTimeIndex,
        selectSourcesOrder,
    }) => ({
        selectRunImpactParams: createSelector(
            selectGroupId,
            selectDateRangeImpactRuns,
            (groupId, dateRange) => {
                if (groupId && dateRange) {
                    const params: HttpParamsOptions = {
                        fromObject: {
                            group_id: groupId,
                            evaluation_time__gte: dateRange.start,
                            evaluation_time__lte: dateRange.end,
                            expand: 'domain',
                        },
                    };
                    return params;
                }

                return null;
            }
        ),
        selectRunConfigParams: createSelector(selectGroupId, (groupId) => {
            if (groupId) {
                const params: HttpParamsOptions = {
                    fromObject: {
                        group_id: groupId,
                        expand: 'domain',
                    },
                };
                return params;
            }

            return null;
        }),
        selectConfigById: (id) =>
            createSelector(selectConfigImpact, (configs) => {
                const config = configs.find((v) => v.id === id);
                return config ?? null;
            }),
        selectStationParams: createSelector(
            selectGroupId,
            selectActiveMmt,
            selectActiveRun,
            selectMeasureScheme,
            (groupId, mmt, activeRun, scheme) => {
                if (groupId && activeRun) {
                    const begin = moment(activeRun.evaluation_time)
                        .add(activeRun.step_minutes, 'minutes')
                        .valueOf();
                    const end = moment(activeRun.evaluation_time)
                        .add(activeRun.duration_minutes, 'minutes')
                        .valueOf();
                    const params: HttpParamsOptions = {
                        fromObject: {
                            group_id: groupId,
                            date__gt: new Date(begin).toISOString(),
                            date__lt: new Date(end).toISOString(),
                            interval: getIntervalByRun(activeRun.step_minutes),
                            measure_scheme:
                                scheme !== MeasureScheme.mpc ? scheme : MeasureScheme.default,
                            concentration_in_mpc: scheme === MeasureScheme.mpc,
                            obj_filter: 'outdoor_post',
                            packet_type: mmt,
                        },
                    };
                    return params;
                }
                return null;
            }
        ),
        isWindLayerAvailable: createSelector(selectActiveRun, (activeRun) => activeRun?.wind_on),
        selectSpeciesList: createSelector(selectActiveRun, (activeRun) => activeRun?.species_list),
        selectImpactHeights: createSelector(
            selectActiveRun,
            (activeRun) => activeRun?.heights ?? []
        ),
        selectImpactSchemaZones: createSelector(
            selectMeasureScheme,
            selectActiveMmt,
            selectMeasuresZones,
            (scheme, currentMmt, zones) => {
                if (zones && scheme && currentMmt && zones[scheme][currentMmt]) {
                    return {
                        scheme: scheme,
                        mmt: currentMmt,
                        zone: zones[scheme][currentMmt] as ColorZone,
                    };
                }
                return null;
            }
        ),
        selectParamsControlPointsByRun: createSelector(
            selectGroupId,
            selectActiveRun,
            selectActiveHeight,
            selectActiveMmt,
            selectMeasureScheme,
            (groupId, activeRun, height, mmt, scheme) => {
                if (groupId && activeRun && height) {
                    const params: HttpParamsOptions = {
                        fromObject: {
                            measure_scheme: getVangaScheme(scheme),
                            h: height.toString(),
                            species: mmt,
                        },
                    };

                    return { id: activeRun.id, params };
                }
                return null;
            }
        ),
        selectImpactTime: createSelector(selectDates, selectImpactTimeIndex, (dates, index) =>
            new Date(dates[index]).getTime()
        ),
        selectActiveSources: createSelector(
            selectActiveRun,
            selectSourcesImpact,
            (run, sources) => {
                if (run?.sources?.length) {
                    const result = [];
                    run.sources.forEach((v) => {
                        if (sources[v]) {
                            result.push(sources[v]);
                        }
                    });
                    return result;
                }
                return [];
            }
        ),
        selectActiveRunSources: createSelector(
            selectActiveRun,
            selectSourcesOrder,
            (run, colorOrder) => {
                if (run) {
                    return { sources: run.sources_snapshot, order: colorOrder };
                }
            }
        ),
        selectParamsForLocality: createSelector(
            selectMeasureScheme,
            selectGroupId,
            selectCurrentCity,
            (scheme, groupId, city) => {
                if (scheme && groupId && city) {
                    const params: QueryParams = {
                        date__gt: DATE_FOR_CITY.date__gt,
                        date__lt: DATE_FOR_CITY.date__lt,
                        interval: '1',
                        measure_scheme:
                            scheme !== MeasureScheme.mpc ? scheme : MeasureScheme.default,
                        concentration_in_mpc: (scheme === MeasureScheme.mpc).toString(),
                        group_id: groupId,
                    };
                    return {
                        cityId: city.id,
                        params,
                    };
                }
                return null;
            }
        ),
    }),
});

export const {
    selectIsActiveImpact,
    selectActiveTab,
    selectIsLoading,
    selectIsLoadingRun,
    selectRunsLoadError,
    selectSources,
    selectDateRangeImpactRuns,
    selectRunImpactParams,
    selectRunConfigParams,
    selectConfigById,
    selectIsLoadingEditRun,
    selectErrorEditRun,
    selectSuccessEditRun,
    selectActiveRun,
    selectActiveMmt,
    selectStationParams,
    isWindLayerAvailable,
    selectShowWindOnMap,
    selectDisableWindButton,
    selectShowLayerOnMap,
    selectDisplayPinsOnMap,
    selectSpeciesList,
    selectImpactHeights,
    selectActiveHeight,
    selectImpactSchemaZones,
    selectImpactTimeIndex,
    selectParamsControlPointsByRun,
    selectIsLoadingControlPoints,
    selectDates,
    selectChartData,
    selectImpactTime,
    selectActivePin,
    selectActiveSources,
    selectExpandPin,
    selectEditControlPoint,
    selectActiveRunSources,
    selectSourcesOrder,
    selectParamsForLocality,
    selectCurrentCityData,
} = impactFeature;
export const getControlPointImpactValue = (id) =>
    createSelector(
        selectActiveMmt,
        selectImpactTimeIndex,
        selectControlPointsTimelineDic,
        (mmt, index, points) => {
            if (!isFalseNumber(index) && mmt && points) {
                const arr = points[id]?.timeseries?.[mmt];
                return arr ? arr[index] : null;
            }
        }
    );
export const isDraggableControlPoint = (point) =>
    createSelector(selectEditControlPoint, selectControlPoints, (controlPoint, controlPoints) => {
        if (controlPoint && point) {
            return controlPoint?.id === point.id;
        }
        const newControlPoint = controlPoints.find(
            (item) => item.obj === NEW_CONTROL_POINT_OBJ_TYPE
        );
        if (newControlPoint && point.id === null) {
            return true;
        }
        return false;
    });
export const selectControlPointsForMap = createSelector(
    selectControlPoints,
    selectControlPointsTimelineDic,
    selectDates,
    (points, pointsTimeline, dates) => {
        const result: ControlPoint[] = [];
        points?.forEach((point) => {
            const timeline = { ...pointsTimeline[point.id]?.timeseries, date: dates };
            const contributions = { ...pointsTimeline[point.id]?.contributions, date: dates };
            const controlPoint: ControlPoint = {
                name: point.name,
                lat: point.lat,
                lon: point.lon,
                id: point.id.toString(),
                obj: point.obj,
                timeline,
                contributions,
            };
            result.push(controlPoint);
        });
        return result;
    }
);
export const selectImpactTimeRangeWithoutDistinct = createSelector(selectDates, (dates) => ({
    begin: new Date(dates[0]).getTime(),
    end: new Date(dates[dates.length - 1]).getTime(),
}));

export const selectImpactTimeRange = pipe(
    select(selectImpactTimeRangeWithoutDistinct),
    distinctUntilChanged(
        (prev, current) => prev.begin === current.begin && prev.end === current.end
    )
);
export const selectImpactTilesParams = createSelector(
    selectDates,
    selectImpactTimeIndex,
    selectActiveHeight,
    selectActiveMmt,
    (dates, index, height, mmt) => {
        if (dates && dates[index] && height && mmt) {
            const params: TilesUpdateParams = {
                ts: new Date(dates[index]).getTime(),
                height: height,
                substance: mmt,
            };
            return params;
        }
        return null;
    }
);
