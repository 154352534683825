import * as moment from 'moment-timezone';
import { DateRangeImpact } from '@cityair/modules/impact/service/api-model-impact';

export const INIT_IMPACT_DATE: DateRangeImpact = {
    start: moment().startOf('day').subtract(8, 'days').toISOString(),
    end: moment().add(1, 'day').endOf('day').toISOString(),
};
export const DATE_FOR_CITY = {
    date__gt: moment().subtract(20, 'minutes').toISOString(),
    date__lt: moment().toISOString(),
};
