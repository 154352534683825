import {
    createFeature,
    createReducer,
    createSelector,
    createActionGroup,
    props,
    on,
    emptyProps,
} from '@ngrx/store';
import type { BBox } from 'geojson';

import {
    DateRangeNetwork,
    NetworkDataModeling,
    NetworkMapData,
    NetworkPostData,
    NetworkPriorityData,
    PostData,
} from '@cityair/modules/network/service/api-models';
import {
    INIT_NETWORK_DATE,
    DOMAIN_BBOX,
    DEFAULT_POST_COLOR,
} from '@cityair/modules/network/constants';
import { selectMeasuresZones } from '@cityair/modules/core/store/selectors';
import { CO, NO2, PM25 } from '@libs/common/consts/substance.consts';
import { ColorZone } from '@libs/common/types/color-zone';
import { MeasureScheme } from '@libs/common/enums/measure-scheme';
import { getColorFromZone } from '@cityair/utils/utils';

const NETWORK_FEATURE_KEY = 'network';

export const NetworkActions = createActionGroup({
    source: 'Network',
    events: {
        'Init Module': emptyProps(),
        'Set Is Available': props<{ payload: boolean }>(),
        'Toggle Layer Map': props<{ payload: boolean }>(),
        'Set Loading': props<{ payload: boolean }>(),
        'Update Date Range': props<{ payload: DateRangeNetwork }>(),
        'Set Active Mmt': props<{ payload: string }>(),
        'Set Time Index': props<{ payload: number }>(),
        'Set Data Modeling': props<{ payload: NetworkDataModeling }>(),
        'Set Data Map': props<{ payload: NetworkMapData }>(),
        'Set Priority Data': props<{ payload: NetworkPriorityData }>(),
        'Set Post Data': props<{ payload: NetworkPostData }>(),
    },
});
interface NetworkState {
    isActiveNetwork: boolean;
    domain: BBox;
    isLoading: boolean;
    showLayerOnMap: boolean;
    dateRange: DateRangeNetwork;
    networkTimeIndex: number;
    allMmts: string[];
    activeMmt: string;
    activePost: string;
    hoverPost: string;
    dataModeling: NetworkDataModeling;
    dataMap: NetworkMapData;
    priorityData: NetworkPriorityData;
    postData: NetworkPostData;
}
const initialState: NetworkState = {
    isActiveNetwork: false,
    domain: DOMAIN_BBOX,
    isLoading: true,
    showLayerOnMap: true,
    dateRange: INIT_NETWORK_DATE,
    networkTimeIndex: 0,
    allMmts: [PM25, CO, NO2],
    activeMmt: PM25,
    activePost: null,
    hoverPost: null,
    dataModeling: null,
    dataMap: null,
    priorityData: null,
    postData: null,
};

export const networkFeature = createFeature({
    name: NETWORK_FEATURE_KEY,
    reducer: createReducer(
        initialState,
        on(NetworkActions.setIsAvailable, (state, { payload }) => ({
            ...state,
            isActiveNetwork: payload,
        })),
        on(NetworkActions.toggleLayerMap, (state, { payload }) => ({
            ...state,
            showLayerOnMap: payload === null ? !state.showLayerOnMap : payload,
        })),
        on(NetworkActions.setLoading, (state, { payload }) => ({
            ...state,
            isLoading: payload,
        })),
        on(NetworkActions.setActiveMmt, (state, { payload }) => ({
            ...state,
            activeMmt: payload,
        })),
        on(NetworkActions.setDataMap, (state, { payload }) => ({
            ...state,
            dataMap: payload,
        })),
        on(NetworkActions.setPriorityData, (state, { payload }) => ({
            ...state,
            priorityData: payload,
        })),
        on(NetworkActions.setPostData, (state, { payload }) => ({
            ...state,
            postData: payload,
        }))
    ),
    extraSelectors: ({
        selectActiveMmt,
        selectIsActiveNetwork,
        selectShowLayerOnMap,
        selectActivePost,
        selectDataMap,
        selectPriorityData,
        selectPostData,
    }) => ({
        selectNetworkSchemaZones: createSelector(
            selectActiveMmt,
            selectMeasuresZones,
            (currentMmt, zones) => {
                const scheme = MeasureScheme.default;
                if (zones && currentMmt && zones[scheme][currentMmt]) {
                    return {
                        scheme: scheme,
                        mmt: currentMmt,
                        zone: zones[scheme][currentMmt] as ColorZone,
                    };
                }
                return null;
            }
        ),
        selectIsShowNetworkGridLayer: createSelector(
            selectIsActiveNetwork,
            selectShowLayerOnMap,
            (isActive, isShow) => isActive && isShow
        ),
        selectPolygons: createSelector(
            selectActiveMmt,
            selectDataMap,
            (mmt, data) => data?.[mmt] ?? null
        ),
        selectPriorityDataByMmt: createSelector(
            selectActiveMmt,
            selectPriorityData,
            (mmt, data) => data?.[mmt] ?? null
        ),
        selectPostDataByMmt: createSelector(
            selectActiveMmt,
            selectPostData,
            (mmt, data) => data?.[mmt] ?? null
        ),
    }),
});

export const {
    selectDomain,
    selectIsActiveNetwork,
    selectPolygons,
    selectAllMmts,
    selectActiveMmt,
    selectNetworkSchemaZones,
    selectDateRange,
    selectShowLayerOnMap,
    selectIsShowNetworkGridLayer,
    selectActivePost,
    selectHoverPost,
    selectDataModeling,
    selectPostData,
    selectPriorityDataByMmt,
    selectPostDataByMmt,
} = networkFeature;
export const selectColorByPostId = (id) =>
    createSelector(selectPostDataByMmt, selectNetworkSchemaZones, (data, zones) => {
        const currentPost = data?.find((v) => v.id === id);
        if (currentPost && zones) {
            return getColorFromZone(zones?.zone, currentPost.value);
        }
        return DEFAULT_POST_COLOR;
    });
