<div class="impact_runs scroll">
    <div class="impact_runs__content">
        <div class="loading-config" *ngIf="store.select(selectIsLoading) | async; else runConfigTemplate">
            <ca-spinner [color]="'#BFE2F8'"></ca-spinner>
        </div>
        <ng-template #runConfigTemplate>
            <ng-container *ngIf="store.select(selectConfigImpact) | async as configs">
                <ng-container *ngIf="configs?.length === 0 else content">
                    <non-data-screen
                        class="screen-placeholders"
                        [text]="textsPlumes.noRunsAvailable"
                    ></non-data-screen>
                </ng-container>
                <ng-template #content>
                    <ca-card
                        class="impact_runs-run-config__item"
                        *ngFor="let card of configs"
                        [title]="card.name"
                        [isActive]="card.is_active"
                        [statusText]="textsPlumes.runConfigurationStatus"
                        [actionsActive]="popupActive"
                        [actionsTooltipText]="textAction"
                        [hasActions]="(store.select(selectUserRoleId) | async) === ADMIN_ID.toString()"
                        (openActionsPopup)="openActionsPopup($event, card)"
                    >
                        <div subtitle-selector>
                            <div class="run-result-link" (click)="goToRunResult(card)">
                                {{textsPlumes.runResults}}
                            </div>
                        </div>
                        <ca-config-run-card
                            [minutes]="card.schedule_period"
                            [valueTypes]="card.species_list"
                            [sources]="card?.sources"
                            [windIsOn]="card?.wind_on"
                            [durations]="card?.duration_minutes"
                            [sourcesSnapshot]="store.select(selectSourcesImpact) | async"
                        ></ca-config-run-card>
                    </ca-card>
                </ng-template>
            </ng-container>
        </ng-template>
    </div>
</div>

<ng-template #actionsOutlet>
    <card-actions-list [position]="popupPositionTop" (clickOutside)="closeActionsPopup($event)">
        <card-actions-item
            [text]="textsPlumes.editRunConfigurationAction"
            (click)="editRunConfiguration($event, selectedCard)"
        ></card-actions-item>
        <card-actions-separator></card-actions-separator>
        <ng-container *ngIf="selectedCard.is_active; else notActive">
            <card-actions-item
                [text]="textsPlumes.stopRunConfigurationAction"
                (click)="stopRuns($event, selectedCard)"
            ></card-actions-item>
        </ng-container>

        <ng-template #notActive>
            <card-actions-item
                [text]="textsPlumes.startRunConfigurationAction"
                (click)="stopRuns($event, selectedCard)"
            ></card-actions-item>
        </ng-template>
    </card-actions-list>
</ng-template>

