import * as moment from 'moment-timezone';
import { TIME_FORMAT } from '@libs/common/consts/date-format.const';
import { formatDayMonth } from '@cityair/config';
import { RunImpact, SourceImpact } from '@cityair/modules/impact/service/api-model-impact';
import { MeasureScheme } from '@libs/common/enums/measure-scheme';

export function formatTime(m: moment.Moment) {
    return m.format(TIME_FORMAT);
}
export function formatDate(m: moment.Moment) {
    return `${formatTime(m)} ${formatDayMonth(m)}`;
}

export function getSourcesByRuns(runs: RunImpact[]): SourceImpact[] {
    const configIds = [];
    const result = [];
    const resultIds = [];
    runs.forEach((run) => {
        if (!configIds.includes(run.config)) {
            configIds.push(run.config);
            run.sources_snapshot?.forEach((source) => {
                if (!resultIds.includes(source.id)) {
                    resultIds.push(source.id);
                    result.push(source);
                }
            });
        }
    });
    return result;
}
type VangaSchemeType = 'default' | 'mpc_instantaneous' | 'c_mmhg_mg';
export function getVangaScheme(scheme: MeasureScheme): VangaSchemeType {
    switch (scheme) {
        case MeasureScheme.mpc:
            return 'mpc_instantaneous';
            break;
        case MeasureScheme.c_mmhg_mg:
            return 'c_mmhg_mg';
            break;
        default:
            return 'default';
            break;
    }
}
