<div class="impact-run-result">
    <div class="impact-run-result__config-header">
        <div class="config-name">
            <span>
                {{config?.name}}
            </span>
        </div>
        <div class="wind" *ngIf="run.wind_on">
            <span>{{textPlumes.quickWindButton}}</span>
        </div>
    </div>
    <div class="impact-run-result__evaluated">
        <div class="impact-run-result__forecast">
            <div class="impact-run-result__subtitle">
                {{ textPlumes.forecastOn }}
            </div>
            <div class="impact-run-result__item impact-run-result__item-bold" >
                {{ run | impactRunTime }}
            </div>
        </div>
        <div class="impact-run-result__item run-time">
            {{ textPlumes.resultsAt }} {{run | finishRunTime}}
        </div>
    </div>
</div>
