import { Pipe, PipeTransform } from '@angular/core';
import { declOfNum } from '@libs/common/utils/utils';
import { TEXTS } from '@libs/common/texts/texts';

@Pipe({ name: 'durationsRun' })
export class DurationsRunPipe implements PipeTransform {
    transform(durations: number): string {
        const hours = durations / 60;
        return `${hours} ${declOfNum(hours, TEXTS.PLUMES.timeIntervalHours)}`;
    }
}
