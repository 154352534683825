import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { OffPanelPopupService } from '@cityair/modules/core/services/off-panel-popup.service';
import { isFalseNumber } from '@libs/common/utils/utils';
import { ControlPointImpact } from '@cityair/modules/impact/service/api-model-impact';

@Component({
    selector: 'ca-impact-control-point',
    templateUrl: 'impact-control-point.component.html',
    styleUrls: ['impact-control-point.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ImpactControlPointComponent {
    @Input() currentPointValues: number;
    @Input() measureUnit: string;
    @Input() point: ControlPointImpact;
    @Input() isActive: boolean;
    @Input() numberAfterDot: number;

    @Output() openActionsPopup = new EventEmitter<any>();

    popupActive = false;

    isFalseNumber = isFalseNumber;

    constructor(public popupProvider: OffPanelPopupService) {}

    openActions($event) {
        this.openActionsPopup.emit($event);
    }
}
