import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TEXTS } from '@libs/common/texts/texts';
import { RunConfigImpact, RunImpact } from '@cityair/modules/impact/service/api-model-impact';

@Component({
    selector: 'ca-impact-run-result',
    templateUrl: 'impact-run-result.component.html',
    styleUrls: ['impact-run-result.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ImpactRunResultComponent {
    @Input() run: RunImpact;
    @Input() config: RunConfigImpact;
    textPlumes = TEXTS.PLUMES;
}
