<cityair-map-control *ngIf="store.select(selectActiveRun)| async as run"
    [isOpenSidebar]="store.select(selectIsSidebarOpen) | async"
    [title]="textTitleMapControl"
>
    <div map-control-period>
        <div class="impact-map-control-period">
            {{ run | impactRunTime }}
        </div>
    </div>
    <div map-control-mmts>
        <shared-ui-mmt-selector
            class="impact-mmt"
            [showLayerButton]="true"
            [activeLayer]="store.select(selectShowLayerOnMap) | async"
            [title]="textOnMap"
            [mmts]="store.select(selectSpeciesList) | async"
            [activeMmt]="store.select(selectActiveMmt) | async"
            (selectMmt)="changeMmt($event)"
            (toggleLayer)="togglePlumesLayer()"
        ></shared-ui-mmt-selector>
    </div>
    <div switch-selector>
        <ca-layer-button
            class="layer_button lens"
            [type]="LayerButtonIconType.SWITCH_POST"
            [size]="'big'"
            [active]="store.select(selectDisplayPinsOnMap) | async"
            (clickOnIcon)="togglePostPin()"
            caTooltip
            caTooltipPlacement="left"
            [caTooltipFollowPointer]="true"
            [caTooltipTemplate]="switchButtonTooltip"
        >
        </ca-layer-button>
        <ng-template #switchButtonTooltip>
            <ca-tooltip [text]="(store.select(selectDisplayPinsOnMap) | async) ? textsSwitchButton.on : textsSwitchButton.off"></ca-tooltip>
        </ng-template>
    </div>
</cityair-map-control>
