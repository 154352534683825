import * as moment from 'moment-timezone';
import { DateRangeNetwork } from '@cityair/modules/network/service/api-models';
import type { BBox, Point } from 'geojson';
export const INIT_NETWORK_DATE: DateRangeNetwork = {
    start: moment().startOf('day').subtract(14, 'days').toISOString(),
    end: moment().add(1, 'day').endOf('day').toISOString(),
};
export const DOMAIN_BBOX: BBox = [82.2975, 54.5, 83.6567625, 55.4036];
export const DEFAULT_POST_COLOR = '#b8bfcc';
export const LIST_PRIORITY_LIMIT = 7;
export const PRIORITY_VALUE = {
    PM25: 60,
    CO2: 3000,
    NO2: 40,
};
export const LABEL_VALUE = [
    'A',
    'B',
    'C',
    'D',
    'E',
    'F',
    'G',
    'H',
    'I',
    'J',
    'K',
    'L',
    'M',
    'N',
    'O',
    'P',
    'Q',
    'R',
    'S',
    'T',
    'U',
];
export const DATA_MODELING = {
    PM25: [
        [10, 11, 14, 8, 6, 9, 3, 4, 6, 7, 12, 11, 10, 3, 4, 2, 5, 7, 8, 10, 2, 7, 8, 10, 2],
        [10, 11, 14, 8, 6, 9, 3, 4, 6, 7, 12, 11, 10, 3, 4, 2, 5, 7, 8, 10, 2, 7, 8, 10, 2],
        [10, 11, 23, 25, 28, 32, 23, 4, 6, 7, 12, 11, 10, 3, 4, 2, 5, 7, 8, 10, 2, 7, 8, 10, 2],
        [10, 11, 23, 25, 28, 32, 23, 6, 7, 12, 11, 10, 3, 4, 2, 5, 7, 8, 10, 2, 7, 8, 10, 2, 5],
        [
            10, 11, 14, 8, 6, 9, 3, 4, 6, 23, 25, 28, 32, 23, 23, 25, 28, 32, 23, 8, 10, 2, 7, 8,
            10, 2,
        ],
        [
            10, 11, 14, 8, 6, 9, 3, 4, 6, 23, 25, 28, 32, 23, 23, 25, 28, 32, 23, 8, 10, 2, 7, 8,
            10, 2,
        ],
        [10, 11, 14, 8, 6, 9, 3, 4, 6, 7, 12, 11, 10, 23, 38, 28, 32, 23, 8, 10, 2, 7, 8, 10, 2],
        [10, 11, 14, 8, 6, 9, 3, 4, 6, 23, 25, 28, 32, 23, 4, 2, 5, 7, 8, 10, 2, 7, 8, 10, 2],
        [10, 11, 14, 8, 6, 9, 3, 33, 28, 28, 32, 23, 10, 38, 324, 2, 45, 7, 8, 10, 2, 7, 8, 10, 2],
        [
            10, 11, 14, 8, 6, 39, 3, 4, 6, 23, 78, 97, 10, 78, 112, 234, 200, 7, 8, 10, 2, 7, 8, 10,
            2,
        ],
        [10, 11, 14, 8, 6, 9, 3, 4, 6, 7, 12, 111, 10, 232, 104, 2, 56, 7, 8, 10, 2, 7, 8, 10, 2],
        [10, 11, 14, 8, 6, 9, 3, 4, 6, 7, 12, 118, 10, 36, 234, 23, 5, 7, 8, 10, 2, 7, 8, 10, 2],
        [
            10, 11, 14, 8, 6, 9, 3, 40, 6, 78, 120, 113, 235, 323, 125, 2, 5, 7, 8, 10, 2, 7, 8, 10,
            2,
        ],
        [10, 11, 14, 8, 6, 9, 3, 40, 6, 107, 120, 78, 234, 303, 4, 2, 5, 76, 8, 10, 2, 7, 8, 10, 2],
        [
            10, 11, 14, 8, 6, 9, 3, 40, 6, 97, 120, 11, 10, 79, 4, 112, 115, 117, 88, 10, 2, 7, 8,
            10, 2,
        ],
        [10, 11, 14, 8, 6, 9, 3, 4, 6, 7, 12, 11, 10, 3, 4, 2, 5, 7, 8, 10, 2, 7, 8, 10, 2],
        [10, 11, 14, 8, 6, 9, 3, 4, 6, 7, 12, 11, 10, 3, 4, 2, 5, 7, 8, 10, 2, 7, 8, 10, 2],
        [10, 11, 14, 8, 6, 9, 3, 4, 6, 7, 12, 11, 10, 3, 4, 2, 5, 7, 8, 10, 2, 7, 8, 10, 2],
        [10, 11, 14, 8, 6, 9, 3, 4, 6, 7, 12, 11, 10, 3, 4, 2, 5, 7, 8, 10, 2, 7, 8, 10, 2],
        [10, 11, 14, 8, 6, 9, 3, 4, 6, 7, 12, 11, 10, 3, 4, 2, 5, 7, 8, 10, 2, 7, 8, 10, 2],
        [10, 11, 14, 8, 6, 9, 3, 4, 6, 7, 12, 11, 10, 3, 4, 2, 5, 7, 8, 10, 2, 7, 8, 10, 2],
    ],
    CO: [
        [10, 11, 14, 8, 6, 9, 3, 4, 6, 7, 12, 11, 10, 3, 4, 2, 5, 7, 8, 10, 2, 7, 8, 10, 2],
        [10, 11, 14, 8, 6, 9, 3, 4, 6, 7, 12, 11, 10, 3, 4, 2, 5, 7, 8, 10, 2, 7, 8, 10, 2],
        [10, 11, 23, 25, 28, 32, 23, 4, 6, 7, 12, 11, 10, 3, 4, 2, 5, 7, 8, 10, 2, 7, 8, 10, 2],
        [10, 11, 23, 25, 28, 32, 23, 6, 7, 12, 11, 10, 3, 4, 2, 5, 7, 8, 10, 2, 7, 8, 10, 2, 5],
        [
            10, 11, 14, 8, 6, 9, 3, 4, 6, 23, 25, 28, 32, 23, 23, 25, 28, 32, 23, 8, 10, 2, 7, 8,
            10, 2,
        ],
        [
            10, 11, 14, 8, 6, 9, 3, 4, 6, 23, 25, 28, 32, 23, 23, 25, 28, 32, 23, 8, 10, 2, 7, 8,
            10, 2,
        ],
        [10, 11, 14, 8, 6, 9, 3, 4, 6, 7, 12, 11, 10, 23, 38, 28, 32, 23, 8, 10, 2, 7, 8, 10, 2],
        [10, 11, 14, 8, 6, 9, 3, 4, 6, 23, 25, 28, 32, 23, 4, 2, 5, 7, 8, 10, 2, 7, 8, 10, 2],
        [10, 11, 14, 8, 6, 9, 3, 33, 28, 28, 32, 23, 10, 38, 324, 2, 45, 7, 8, 10, 2, 7, 8, 10, 2],
        [
            10, 11, 14, 8, 6, 39, 3, 4, 6, 23, 78, 97, 10, 78, 112, 234, 200, 7, 8, 10, 2, 7, 8, 10,
            2,
        ],
        [10, 11, 14, 8, 6, 9, 3, 4, 6, 7, 12, 111, 10, 232, 104, 2, 56, 7, 8, 10, 2, 7, 8, 10, 2],
        [10, 11, 14, 8, 6, 9, 3, 4, 6, 7, 12, 118, 10, 36, 234, 23, 5, 7, 8, 10, 2, 7, 8, 10, 2],
        [
            10, 11, 14, 8, 6, 9, 3, 40, 6, 78, 120, 113, 235, 323, 125, 2, 5, 7, 8, 10, 2, 7, 8, 10,
            2,
        ],
        [10, 11, 14, 8, 6, 9, 3, 40, 6, 107, 120, 78, 234, 303, 4, 2, 5, 76, 8, 10, 2, 7, 8, 10, 2],
        [
            10, 11, 14, 8, 6, 9, 3, 40, 6, 97, 120, 11, 10, 79, 4, 112, 115, 117, 88, 10, 2, 7, 8,
            10, 2,
        ],
        [10, 11, 14, 8, 6, 9, 3, 4, 6, 7, 12, 11, 10, 3, 4, 2, 5, 7, 8, 10, 2, 7, 8, 10, 2],
        [10, 11, 14, 8, 6, 9, 3, 4, 6, 7, 12, 11, 10, 3, 4, 2, 5, 7, 8, 10, 2, 7, 8, 10, 2],
        [10, 11, 14, 8, 6, 9, 3, 4, 6, 7, 12, 11, 10, 3, 4, 2, 5, 7, 8, 10, 2, 7, 8, 10, 2],
        [10, 11, 14, 8, 6, 9, 3, 4, 6, 7, 12, 11, 10, 3, 4, 2, 5, 7, 8, 10, 2, 7, 8, 10, 2],
        [10, 11, 14, 8, 6, 9, 3, 4, 6, 7, 12, 11, 10, 3, 4, 2, 5, 7, 8, 10, 2, 7, 8, 10, 2],
        [10, 11, 14, 8, 6, 9, 3, 4, 6, 7, 12, 11, 10, 3, 4, 2, 5, 7, 8, 10, 2, 7, 8, 10, 2],
    ],
    NO2: [
        [10, 11, 14, 8, 6, 9, 3, 4, 6, 7, 12, 11, 10, 3, 4, 2, 5, 7, 8, 10, 2, 7, 8, 10, 2],
        [10, 11, 14, 8, 6, 9, 3, 4, 6, 7, 12, 11, 10, 3, 4, 2, 5, 7, 8, 10, 2, 7, 8, 10, 2],
        [10, 11, 23, 25, 28, 32, 23, 4, 6, 7, 12, 11, 10, 3, 4, 2, 5, 7, 8, 10, 2, 7, 8, 10, 2],
        [10, 11, 23, 25, 28, 32, 23, 6, 7, 12, 11, 10, 3, 4, 2, 5, 7, 8, 10, 2, 7, 8, 10, 2, 5],
        [
            10, 11, 14, 8, 6, 9, 3, 4, 6, 23, 25, 28, 32, 23, 23, 25, 28, 32, 23, 8, 10, 2, 7, 8,
            10, 2,
        ],
        [
            10, 11, 14, 8, 6, 9, 3, 4, 6, 23, 25, 28, 32, 23, 23, 25, 28, 32, 23, 8, 10, 2, 7, 8,
            10, 2,
        ],
        [10, 11, 14, 8, 6, 9, 3, 4, 6, 7, 12, 11, 10, 23, 38, 28, 32, 23, 8, 10, 2, 7, 8, 10, 2],
        [10, 11, 14, 8, 6, 9, 3, 4, 6, 23, 25, 28, 32, 23, 4, 2, 5, 7, 8, 10, 2, 7, 8, 10, 2],
        [10, 11, 14, 8, 6, 9, 3, 33, 28, 28, 32, 23, 10, 38, 324, 2, 45, 7, 8, 10, 2, 7, 8, 10, 2],
        [
            10, 11, 14, 8, 6, 39, 3, 4, 6, 23, 78, 97, 10, 78, 112, 234, 200, 7, 8, 10, 2, 7, 8, 10,
            2,
        ],
        [10, 11, 14, 8, 6, 9, 3, 4, 6, 7, 12, 111, 10, 232, 104, 2, 56, 7, 8, 10, 2, 7, 8, 10, 2],
        [10, 11, 14, 8, 6, 9, 3, 4, 6, 7, 12, 118, 10, 36, 234, 23, 5, 7, 8, 10, 2, 7, 8, 10, 2],
        [
            10, 11, 14, 8, 6, 9, 3, 40, 6, 78, 120, 113, 235, 323, 125, 2, 5, 7, 8, 10, 2, 7, 8, 10,
            2,
        ],
        [10, 11, 14, 8, 6, 9, 3, 40, 6, 107, 120, 78, 234, 303, 4, 2, 5, 76, 8, 10, 2, 7, 8, 10, 2],
        [
            10, 11, 14, 8, 6, 9, 3, 40, 6, 97, 120, 11, 10, 79, 4, 112, 115, 117, 88, 10, 2, 7, 8,
            10, 2,
        ],
        [10, 11, 14, 8, 6, 9, 3, 4, 6, 7, 12, 11, 10, 3, 4, 2, 5, 7, 8, 10, 2, 7, 8, 10, 2],
        [10, 11, 14, 8, 6, 9, 3, 4, 6, 7, 12, 11, 10, 3, 4, 2, 5, 7, 8, 10, 2, 7, 8, 10, 2],
        [10, 11, 14, 8, 6, 9, 3, 4, 6, 7, 12, 11, 10, 3, 4, 2, 5, 7, 8, 10, 2, 7, 8, 10, 2],
        [10, 11, 14, 8, 6, 9, 3, 4, 6, 7, 12, 11, 10, 3, 4, 2, 5, 7, 8, 10, 2, 7, 8, 10, 2],
        [10, 11, 14, 8, 6, 9, 3, 4, 6, 7, 12, 11, 10, 3, 4, 2, 5, 7, 8, 10, 2, 7, 8, 10, 2],
        [10, 11, 14, 8, 6, 9, 3, 4, 6, 7, 12, 11, 10, 3, 4, 2, 5, 7, 8, 10, 2, 7, 8, 10, 2],
    ],
};
