import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ImpactMainComponent } from '@cityair/modules/impact/components/impact-main.component/impact-main.component';
import { IMPACT_PAGES, IMPACTS_INIT_PAGE } from '@cityair/modules/impact/models';
import { ControlPointsComponent } from '@cityair/modules/impact/components/impact-main.component/control-points/control-points.component';
import { ImpactRunsComponent } from '@cityair/modules/impact/components/impact-main.component/impact-runs/impact-runs.component';
import { ImpactRunResultsComponent } from '@cityair/modules/impact/components/impact-main.component/impact-run-results/impact-run-results.component';
import { ImpactEditRunComponent } from '@cityair/modules/impact/components/impact-main.component/impact-runs/impact-edit-run/impact-edit-run.component';

export const impactRoutes: Routes = [
    {
        path: '',
        component: ImpactMainComponent,
        children: [
            {
                path: '',
                pathMatch: 'full',
                redirectTo: IMPACTS_INIT_PAGE,
            },
            {
                path: IMPACT_PAGES.runResults,
                component: ImpactRunResultsComponent,
            },
            {
                path: IMPACT_PAGES.configs,
                component: ImpactRunsComponent,
            },
            {
                path: IMPACT_PAGES.controlPoints,
                component: ControlPointsComponent,
            },
            {
                path: IMPACT_PAGES.configs + '/' + IMPACT_PAGES.edit + '/:id',
                component: ImpactEditRunComponent,
            },
            /* {
                path: IMPACT_PAGES.controlPoints + '/' + IMPACT_PAGES.add,
                component: ControlPointFormComponent,
            },
            {
                path: IMPACT_PAGES.controlPoints + '/' + IMPACT_PAGES.edit + '/:id',
                component: ControlPointFormComponent,
            }, */
        ],
    },
];

@NgModule({
    imports: [RouterModule.forChild(impactRoutes)],
    exports: [RouterModule],
})
export class ImpactRoutingModule {}
