<div class="impact_control_points">
    <ca-impact-run-dropdown-list
        class="impact_control_points__dropdown_control_point_wrapper"
        [currentRun]="store.select(selectActiveRun) | async"
        [runs]="runs"
        [configs]="store.select(selectConfigImpactDic) | async"
        [isLoadingRun]="store.select(selectIsLoadingRun) | async"
    ></ca-impact-run-dropdown-list>
    <ca-list-header [showCounter]="false">
        <button-add
            [text]="textsPlumes.addControlPoint"
            (action)="openAddCheckpoint()"
        ></button-add>
    </ca-list-header>
    <ng-container *ngIf="store.select(selectIsLoadingControlPoints) | async else listTemplate">
         <ca-spinner [color]="'#BFE2F8'"></ca-spinner>
    </ng-container>
    <ng-template #listTemplate>
        <ng-container  *ngIf="store.select(selectControlPoints) | async as controlPoints">
            <ng-container *ngIf="controlPoints.length === 0 else content">
                    <non-data-screen
                        class="screen-placeholders"
                        [text]="textsPlumes.noControlPoints"
                    ></non-data-screen>
                </ng-container>
            <ng-template #content>
                <div class="impact_control_points__sort_wrapper">
                    <sort-list
                        [properties]="[
                            {
                                props: sortControlPointName,
                                title: textsPlumes.controlPoints
                            },
                            {
                                props: sortControlPointValue,
                                title: mmtNames[currentMeasure]
                            }
                        ]"
                        [cbSortFun]="sortingOfControlPoint"
                        [sortingProps]="sortingControlPoint"
                        [sortingDirection]="sortingDirection"
                    ></sort-list>
                </div>
                <div (scroll)="onScroll($event)" class="impact_control_points__list scroll">
                    <div class="wrapper" >
                         <ca-impact-control-point
                            *ngFor="
                                let point of controlPoints
                                    | sorting : sortingControlPoint : sortingDirection
                            "
                            [currentPointValues]="
                                store.select(getControlPointImpactValue(point.id)) | async
                            "
                            [measureUnit]="
                                textsScheme[store.select(selectMeasureScheme) | async][
                                    currentMeasure
                                ]
                            "
                            [point]="point"
                            [isActive]="point.id.toString() === activeControlPoint"
                            [numberAfterDot]="
                                getDigitsAfterDot(
                                    store.select(selectMeasureScheme) | async,
                                    currentMeasure
                                )
                            "
                            (click)="openControlPointChart(point)"
                            (openActionsPopup)="openActionsPopup($event, point)"
                        ></ca-impact-control-point>
                    </div>
                </div>
           </ng-template>
        </ng-container>
    </ng-template>
    <ng-template #popupDeleteOutlet>
        <stnd-ask-popup
            *ngIf="isDeletePopupOpen"
            [accept]="deleteControlPointAccept"
            [close]="closeDeleteControlPointDialog"
            [texts]="popupDeleteTexts"
        ></stnd-ask-popup>
    </ng-template>

    <ng-template #actionsOutlet>
        <card-actions-list [position]="popupPositionTop" (clickOutside)="closeActionsPopup($event)">
            <card-actions-item
                [text]="editStation.edit"
                (click)="editControlPoint()"
            ></card-actions-item>

            <card-actions-separator></card-actions-separator>

            <card-actions-item
                type="danger"
                [text]="editStation.delete"
                (click)="deleteControlPointDialog($event, selectedPoint)"
            ></card-actions-item>
        </card-actions-list>
    </ng-template>
</div>
