import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, filter, map, switchMap, take, tap, withLatestFrom } from 'rxjs/operators';
import {
    ImpactActions,
    selectRunImpactParams,
    selectRunConfigParams,
    selectActiveRun,
    selectStationParams,
    selectParamsControlPointsByRun,
    selectControlPointsForMap,
    selectSourcesImpact,
    selectSourcesOrder,
    selectParamsForLocality,
} from '@cityair/modules/impact/store/impact.feature';
import { ImpactApiService } from '@cityair/modules/impact/service/impact-api.service';
import { CommonActions, doNothing, refreshVangaToken } from '@cityair/modules/core/store/actions';
import { of } from 'rxjs';
import { HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { getSourcesByRuns } from '@cityair/modules/impact/utils';
import { selectGroupId } from '@cityair/modules/core/store/group/group.feature';
import { ControlPoint } from '@cityair/modules/plumes/services/control-point/models';
import { Dictionary } from '@ngrx/entity';
import { Feature, Properties } from '@libs/common/models/feature';
import { SourceImpact } from '@cityair/modules/impact/service/api-model-impact';
import { onIsEnabledChart } from '@libs/shared-ui/components/timeline-panel/store/core.actions';

import { BasicApi } from '@cityair/modules/core/services/api/basic-api';
import { setCurrentCity } from '@cityair/modules/core/store/current-city/current-city.feature';

function transformControlPoint(
    point: ControlPoint,
    sources: Dictionary<SourceImpact>,
    order: number[]
): Feature[] {
    if (!point) {
        return [];
    }
    const properties: Properties = {
        uuid: point.id,
        name: point.name,
        name_ru: point.name,
        timeseries: point.timeline,
        obj: point.obj,
        has_any_timeseries: !!point?.timeline,
        contributions: point?.contributions,
        contributionsDetails: {
            sources,
            order,
        },
    };

    return [
        {
            type: 'Feature',
            geometry: {
                type: 'Point',
                coordinates: [point?.lon, point?.lat],
            },
            properties,
        },
    ];
}
@Injectable()
export class ImpactEffects {
    constructor(
        private actions$: Actions,
        private store: Store,
        private impactService: ImpactApiService,
        private basicApi: BasicApi
    ) {}

    updateRunDate$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ImpactActions.initModule, ImpactActions.updateDateRangeRun),
            withLatestFrom(this.store.select(selectRunImpactParams)),
            filter(([_, params]) => params !== null),
            tap(() => this.store.dispatch(ImpactActions.setLoading({ payload: true }))),
            switchMap(([action, runParams]) =>
                this.impactService.getRuns(runParams).pipe(
                    switchMap((response) => {
                        const sources = getSourcesByRuns(response);
                        return [
                            ImpactActions.setLoading({ payload: false }),
                            ImpactActions.setRuns({ payload: response }),
                            ImpactActions.setSources({ payload: sources }),
                        ];
                    }),
                    catchError((errorResponse) => {
                        if (errorResponse?.status === HttpStatusCode.Unauthorized) {
                            return of(refreshVangaToken());
                        } else {
                            return of(
                                ImpactActions.setRunLoadError({ payload: true }),
                                ImpactActions.setLoading({ payload: false })
                            );
                        }
                    })
                )
            )
        )
    );

    getRunConfigs = createEffect(() =>
        this.actions$.pipe(
            ofType(ImpactActions.initModule),
            withLatestFrom(this.store.select(selectRunConfigParams)),
            filter(([_, params]) => params !== null),
            switchMap(([action, runParams]) =>
                this.impactService.getConfig(runParams).pipe(
                    switchMap((response) => [ImpactActions.setRunConfigs({ payload: response })]),
                    catchError((errorResponse) => {
                        if (errorResponse?.status === HttpStatusCode.Unauthorized) {
                            return of(refreshVangaToken());
                        } else {
                            return of(doNothing());
                        }
                    })
                )
            )
        )
    );

    getDataForLocality = createEffect(() =>
        this.actions$.pipe(
            ofType(setCurrentCity),
            withLatestFrom(this.store.select(selectParamsForLocality)),
            filter(([_, params]) => params !== null),
            switchMap(([action, { cityId, params }]) =>
                this.basicApi.getCityDataTimeline(cityId, params).pipe(
                    switchMap((response) => {
                        return [ImpactActions.setCityData({ payload: response })];
                    }),
                    catchError((errorResponse: HttpErrorResponse) => {
                        return of(doNothing());
                    })
                )
            )
        )
    );
    getControlPoints = createEffect(() =>
        this.actions$.pipe(
            ofType(ImpactActions.initModule),
            withLatestFrom(this.store.select(selectGroupId)),
            filter(([_, params]) => params !== null),
            switchMap(([action, groupId]) =>
                this.impactService.getControlPoints(groupId).pipe(
                    switchMap((response) => [
                        ImpactActions.setControlPoints({ payload: response }),
                    ]),
                    catchError((errorResponse) => {
                        if (errorResponse?.status === HttpStatusCode.Unauthorized) {
                            return of(refreshVangaToken());
                        } else {
                            return of(doNothing());
                        }
                    })
                )
            )
        )
    );

    getControlPointsTimeLineByRun = createEffect(() =>
        this.actions$.pipe(
            ofType(
                ImpactActions.setActiveRun,
                ImpactActions.setActiveMmt,
                ImpactActions.setActiveHeight
            ),
            withLatestFrom(this.store.select(selectParamsControlPointsByRun)),
            filter(([action, params]) => params !== null),
            tap(() =>
                this.store.dispatch(ImpactActions.setControlPointsIsLoading({ payload: true }))
            ),
            switchMap(([action, params]) =>
                this.impactService.getControlPointsTimeline(params).pipe(
                    switchMap((response) => [
                        ImpactActions.setSourcesOrder({ payload: response?.meta?.sources }),
                        ImpactActions.setDates({ payload: response?.meta.dates }),
                        ImpactActions.setControlPointsIsLoading({ payload: false }),
                        ImpactActions.setControlPointsTimeline({
                            payload: response?.control_points_measurements,
                        }),
                    ]),
                    catchError((error: HttpErrorResponse) => {
                        const actions = [];
                        /* if (this.checkErrorStatus(error?.status)) {
                            const errorAction = this.actionError(error?.status);
                            actions.push(errorAction);
                        }

                        actions.push(setRunLoading({ payload: false })); */
                        actions.push(ImpactActions.setControlPointsIsLoading({ payload: false }));
                        return actions;
                    })
                )
            )
        )
    );

    updateConfig$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ImpactActions.updateRunConfig),
            tap(() => this.store.dispatch(ImpactActions.setLoadingEditRun({ payload: true }))),
            switchMap((action) =>
                this.impactService.updateConfig(action?.payload).pipe(
                    switchMap((response) => {
                        return [
                            ImpactActions.setRunConfig({ payload: response }),
                            ImpactActions.setLoadingEditRun({ payload: false }),
                            ImpactActions.setSuccessEditRun({ payload: true }),
                        ];
                    }),
                    catchError((errorResponse) => {
                        if (errorResponse?.status === HttpStatusCode.Unauthorized) {
                            return of(refreshVangaToken());
                        } else {
                            return of(ImpactActions.setErrorEditRun({ payload: errorResponse }));
                        }
                    })
                )
            )
        )
    );

    setActiveRunByRuns$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ImpactActions.setRuns),
            withLatestFrom(this.store.select(selectActiveRun)),
            switchMap(([action, activeRun]) => {
                const result = action.payload;
                let index = 0;
                if (result && result.length) {
                    if (activeRun) {
                        const indexOld = result.findIndex((item) => item.id === activeRun.id);
                        if (indexOld !== -1) {
                            index = indexOld;
                        }
                    }
                    return [ImpactActions.setActiveRun({ payload: result[index] })];
                }
                return [];
            })
        )
    );

    setChartData$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ImpactActions.setActivePin),
            withLatestFrom(
                this.store.select(selectControlPointsForMap),
                this.store.select(selectSourcesImpact),
                this.store.select(selectSourcesOrder)
            ),
            // filter(([_, params]) => !!params),
            switchMap(([action, points, sources, order]) => {
                const actions = [];
                const id = action?.payload.toString();
                if (id) {
                    const point = points.find((v) => v.id === id);
                    if (point) {
                        const chartData = transformControlPoint(point, sources, order);
                        console.log(chartData);
                        actions.push(ImpactActions.setChartData({ payload: chartData }));
                    }
                }
                return actions;
            })
        )
    );

    enabledChart$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ImpactActions.setChartData),
            map((props) => onIsEnabledChart({ payload: props.payload.length > 0 }))
        )
    );

    showImpactLayerOnMapLoad$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ImpactActions.setIsAvailable),
            map((action) => ImpactActions.toggleLayerMap({ payload: action.payload }))
        )
    );

    setActiveRunLoadStation$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ImpactActions.setActiveRun, ImpactActions.setActiveMmt),
            withLatestFrom(this.store.select(selectStationParams)),
            filter(([_, params]) => !!params),
            switchMap(([action, params]) => {
                return [];
            })
        )
    );

    lodDataForActiveRun$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ImpactActions.setActiveRun),
            withLatestFrom(this.store.select(selectActiveRun)),
            switchMap(([action, activeRun]) => {
                return [];
            })
        )
    );

    reloadDataOnTokenRefresh$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CommonActions.VangaTokenUpdated),
            take(1),
            map((action) => ImpactActions.initModule())
        )
    );
}
